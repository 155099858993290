<template>
  <div class="changeDetailBox" v-loading="loading">
    <div class="option_btn_model">
      <el-button
        size="mini"
        type="primary"
        v-preventReClick
        @click="saveChange"
      >
        <!-- 保存 -->
        {{ $t("label.save") }}
      </el-button>
      <el-button size="mini" @click="cancelChangeDetail">
        <!-- 取消 -->
        {{ $t("label.ems.cancel") }}
      </el-button>
    </div>
    <base-form
      ref="baseFormDetail"
      class="baseForm"
      @addFile="addFile"
      :option-list="optionList"
      :form-attr="formAttr"
      :columnNum="columnNum"
      :oneColumnWidth="oneColumnWidth"
      :twoColumnWidth="twoColumnWidth"
      :status-icon="statusIcon"
      :autoRefresh="autoRefresh"
      :objectApi="objectApi"
      :prefix="prefix"
      @control="control"
      @newSearchableField="newSearchableField"
      @addFieldOption="addFieldOption"
      @remoteMethod="remoteMethod"
      @changeSelect="changeSelect"
      @openBindMobile="openBindMobile"
    ></base-form>
    <!-- 操作按钮 -->
    <div class="option_btn_model">
      <el-button
        size="mini"
        type="primary"
        v-preventReClick
        @click="saveChange"
      >
        <!-- 保存 -->
        {{ $t("label.save") }}
      </el-button>
      <el-button size="mini" @click="cancelChangeDetail">
        <!-- 取消 -->
        {{ $t("label.ems.cancel") }}
      </el-button>
    </div>
    <!-- 添加文件 -->
    <add-files
      ref="addFile"
      :isFromFileCenter="false"
      :dialogAddVisible="dialogAddVisible"
      @addSubmit="addWorkSubmit"
      @close="addWorkClose"
    >
    </add-files>

    <!-- 绑定手机号 -->
    <el-dialog
      :title="mobileTitle"
      :width="'40%'"
      :close-on-click-modal="false"
      :visible.sync="bindMobileDialog"
    >
      <el-form
        :model="bindMobileForm"
        ref="bindMobileForm"
        class="demo-ruleForm"
        label-width="auto"
        :rules="mobileRules"
      >
        <el-form-item :label="$t('label.weixin.telephone')" prop="mobile">
          <el-input
            type="mobile"
            v-model="bindMobileForm.mobile"
            autocomplete="off"
          >
            <el-select
              v-model="bindMobileForm.countryCode"
              slot="prepend"
              style="width: 100px"
            >
              <el-option
                v-for="item in countryCodeList"
                :key="item.value"
                :label="item.value"
                :value="item.value"
              >
                <span style="float: left">{{ item.label }}</span>
                <span style="float: right; padding-left: 50px"
                  >+{{ item.value }}</span
                >
              </el-option>
            </el-select>
          </el-input>
        </el-form-item>
      </el-form>
      <div slot="footer" class="footer-dialog">
        <el-button @click="handleCancelBind('bindMobileForm')">{{
          $t("label.tabpage.cancel")
        }}</el-button>
        <el-button type="primary" @click="handleBindmobile('bindMobileForm')">{{
          $t("label.weixin.binding")
        }}</el-button>
      </div>
    </el-dialog>
  </div>
</template>

<script>
import { AddFiles } from "@/components/index";
import {
  editPersonalInformation,
  savePersonalInformation,
  bindMobile,
} from "@/views/profileSetting/profileSettingApi.js";
import * as CommonObjApi from "../profileSettingApi";
import INPUTTYPE from "@/config/enumCode/inputType";
import BaseForm from "./Form/index.vue";

export default {
  components: {
    BaseForm,
    AddFiles,
  },
  created() {
    this.getEditPersonalInformation();
  },
  data() {
    // 手机号验证规则
    var bindMobileRule = (rule, value, callback) => {
      let phoneRe = "";
      // 添加正则表达式
      switch (this.bindMobileForm.countryCode) {
        case "86":
          phoneRe =
            /^1(3\d|4[5-9]|5[0-35-9]|6[567]|7[0-8]|8\d|9[0-35-9])\d{8}$/;
          break;
        case "852":
          phoneRe = /^(852)?(2[1-9]|3[145679])\d{6}$/;
          break;
        case "853":
          phoneRe = /^(853)?(?:28\d{6}|6\d{7})$/;
          break;
        case "886":
          // eslint-disable-next-line no-useless-escape
          phoneRe = /^(\+?886\-?|0)?9\d{8}$/;
          break;
        case "1":
          phoneRe = /^(\+?1)?[2-9]\d{2}[2-9](?!11)\d{6}$/;
          break;
        case "81":
          // eslint-disable-next-line no-useless-escape
          phoneRe = /^(\+?81|0)\d{1,4}[ \-]?\d{1,4}[ \-]?\d{4}$/;
          break;
        case "49":
          phoneRe =
            // eslint-disable-next-line no-useless-escape
            /^(\+?49[ \.\-])?0?[19][1567]\d{1,2}(?:[ /-]*\d){7,8}(?!\d)/;
          break;
        case "7":
          phoneRe = /^(\+?7|8)?9\d{9}$/;
          break;
        case "44":
          phoneRe = /^(\+?44|0)7\d{9}$/;
          break;
        case "33":
          phoneRe = /^(\+?33|0)[67]\d{8}$/;
          break;
        case "39":
          phoneRe = /^(\+?39)?\s?3\d{2} ?\d{6,7}$/;
          break;
        case "34":
          phoneRe = /^(\+?34)?(6\d{1}|7[1234])\d{7}$/;
          break;
        case "31":
          phoneRe = /^(\+?31)?0?(6)\d{8}$/;
          break;
        case "48":
          phoneRe = /^(\+?48)? ?[5-8]\d ?\d{3} ?\d{2} ?\d{2}$/;
          break;
        case "55":
          // eslint-disable-next-line no-useless-escape
          phoneRe = /^(\+?55|0)\-?[1-9]{2}\-?[2-9]{1}\d{3,4}\-?\d{4}$/;
          break;
        case "82":
          // eslint-disable-next-line no-useless-escape
          phoneRe = /^((\+?82)\-?)?0?[71](?:\d{8,9})$/;
          break;

        default:
          phoneRe =
            /^1(3\d|4[5-9]|5[0-35-9]|6[567]|7[0-8]|8\d|9[0-35-9])\d{8}$/;
          break;
      }
      // 提示文字:手机格式有误，请重新录入
      const text = this.$i18n.t("front-kefu-moudle-v1-sjgsbzq-reinput");
      if (!value) {
        return callback(new Error(text));
      } else if (!phoneRe.test(value)) {
        return callback(new Error(text));
      } else {
        callback();
      }
    };
    return {
      loading: true, // loading
      fieldProp: "",
      dialogAddVisible: false, // 添加文件
      newObjLevel: 0, // 新建查找字段标识
      currencyList: [], // 货币list
      // baseForm组件参数
      attrIndex: -1, // 表单字段所在位置

      formAttr: null,
      columnNum: 2,
      oneColumnWidth: "100%",
      twoColumnWidth: "50%",
      statusIcon: true,

      optionList: {},
      id: this.$store.state.userInfoObj.userId, //当前用户id
      autoRefresh: false,
      objectApi: "ccuser", // 对象api
      objId: "user", // 对象id
      prefix: "005", // 对象prefix
      controlContainer: null, //依赖关系
      relatedControlContainer: [], //依赖关系

      // 绑定手机号弹框
      bindMobileDialog: false,
      // 绑定手机号弹框表单值
      bindMobileForm: {
        // 手机号
        mobile: "",
        // 区号
        countryCode: "86",
      },
      // 区号选项
      countryCodeList: [
        { label: this.$i18n.t("c938"), value: "86" },
        { label: this.$i18n.t("c939"), value: "853" },
        { label: this.$i18n.t("c940"), value: "852" },
        { label: this.$i18n.t("c941"), value: "886" },
        { label: this.$i18n.t("c942"), value: "1" },
        { label: this.$i18n.t("c943"), value: "81" },
        { label: this.$i18n.t("c944"), value: "49" },
        { label: this.$i18n.t("c945"), value: "7" },
        { label: this.$i18n.t("c946"), value: "44" },
        { label: this.$i18n.t("c947"), value: "33" },
        { label: this.$i18n.t("c948"), value: "39" },
        { label: this.$i18n.t("c949"), value: "34" },
        { label: this.$i18n.t("c950"), value: "31" },
        { label: this.$i18n.t("c951"), value: "48" },
        { label: this.$i18n.t("c952"), value: "55" },
        { label: this.$i18n.t("c953"), value: "82" },
      ],
      // 新建或修改手机号
      mobileTitle: "",
      // 绑定手机号验证规则
      mobileRules: {
        mobile: [{ validator: bindMobileRule, trigger: ["blur", "change"] }],
      },
    };
  },
  methods: {
    // 添加文件
    addFile(field) {
      this.fieldProp = field.prop;
      this.$refs.addFile.initPage();
      this.dialogAddVisible = true;
    },
    // 添加文件
    addWorkSubmit(checkedFileList) {
      let ids = [];
      checkedFileList.forEach((file) => {
        if (file.fileInfoId) {
          ids.push(file.fileInfoId);
        } else {
          ids.push(file.id);
        }
      });
      this.$set(
        this.$refs.baseFormDetail.formData,
        this.fieldProp,
        ids.toString()
      );
      this.formAttr.map((attr) => {
        if (attr.prop === this.fieldProp) {
          attr.data = checkedFileList;
        }
      });
      this.addWorkClose();
    },
    // 关闭添加文件
    addWorkClose() {
      this.dialogAddVisible = false;
    },
    // 依赖关系
    setControlContainer() {
      // 先将被控字段选项清空,循环后有对应选项再进行赋值
      this.controlContainer.forEach((control) => {
        if (
          Array.isArray(control.controlVals) ||
          control.controlVals.length > 0
        ) {
          control.controlVals.forEach((vals) => {
            vals.dependFields.forEach((dependField) => {
              this.formAttr.forEach((controlAttr) => {
                if (controlAttr.fieldId === dependField.dependfieldid) {
                  this.optionList[controlAttr.prop] = [];
                }
              });
            });
          });
        }
      });

      this.controlContainer.forEach((control) => {
        // 控制字段
        this.formAttr.forEach((attr) => {
          if (attr.fieldId === control.controlfield) {
            this.control(
              attr.value,
              attr.fieldId,
              this.$refs.baseFormDetail.formData,
              true
            );
          }
        });
      });
    },
    // 依赖字段 ,initControl为true时是新建初始化，已经将选项值都清空，无需再次清理
    control(selectVal, fieldId, formData, initControl = false) {
      // 被控制字段集合
      let controledFieldId = [];
      let controledFieldProp = [];

      // 查找对应规则
      let control = this.controlContainer.find((control) => {
        return control.controlfield === fieldId;
      });

      if (control !== undefined) {
        // 先将被控字段选项清空,循环后有对应选项再进行赋值
        if (!initControl) {
          control.controlVals.forEach((vals) => {
            vals.dependFields.forEach((dependField) => {
              this.formAttr.forEach((controlAttr) => {
                if (controlAttr.fieldId === dependField.dependfieldid) {
                  controledFieldId.push(controlAttr.fieldId);
                  controledFieldProp.push(controlAttr.prop);
                  this.$set(this.optionList, controlAttr.prop, []);
                }
              });
            });
          });
        }

        // 查找当前值是否有控制规则
        let controlCondition = control.controlVals.find((item) => {
          return item.val === selectVal;
        });
        if (controlCondition === undefined) {
          controledFieldId.forEach((fieldId) => {
            this.formAttr.forEach((controlAttr) => {
              if (controlAttr.fieldId === fieldId) {
                // 清空被控字段值并递归
                if (controlAttr.fieldType === "Q") {
                  this.$set(formData, controlAttr.apiname, []);
                } else {
                  this.$set(formData, controlAttr.apiname, "");
                }
                this.control(formData[controlAttr.apiname], fieldId, formData);
              }
            });
          });

          // control.controlVals.forEach((vals) => {
          //   // 控制值
          //   vals.dependFields.forEach((dependField) => {
          //     this.formAttr.forEach((controlAttr, idx) => {
          //       if (controlAttr.fieldId === dependField.dependfieldid) {
          //         // 清空被控字段值并递归
          //         if (controlAttr.fieldType === 'Q') {
          //           this.$set(formData, controlAttr.apiname, [])
          //         } else {
          //           this.$set(formData, controlAttr.apiname, '')
          //         }
          //         this.control(
          //           formData[controlAttr.apiname],
          //           dependField.dependfieldid,
          //           formData
          //         )
          //       }
          //     })
          //   })
          // })
        } else {
          // 控制选项列表和选项值
          controlCondition.dependFields.forEach((dependField) => {
            this.formAttr.forEach((controlAttr) => {
              if (controlAttr.fieldId === dependField.dependfieldid) {
                let options = [];
                dependField.vals.forEach((element) => {
                  options.push({
                    key: element.deflangval,
                    val: element.val,
                  });
                });
                this.optionList[controlAttr.prop] = options;

                // 若当前选中值不在选项列表中，则清空
                let option = options.find((item) => {
                  return item.val === formData[controlAttr.apiname];
                });
                if (option === undefined) {
                  // 清空被控字段值并递归
                  if (controlAttr.fieldType === "Q") {
                    this.$set(formData, controlAttr.apiname, []);
                  } else {
                    this.$set(formData, controlAttr.apiname, "");
                  }
                  // 如果被控制字段选项中包含字段默认值，将依赖字段的值赋为默认值
                  if (
                    controlAttr.defaultValue &&
                    controlAttr.defaultValue !== ""
                  ) {
                    let option = options.find((item) => {
                      return item.val === controlAttr.defaultValue;
                    });
                    if (option !== undefined) {
                      this.$set(
                        formData,
                        controlAttr.apiname,
                        controlAttr.fieldType === "Q"
                          ? [controlAttr.defaultValue]
                          : controlAttr.defaultValue
                      );
                    }
                  }
                  this.control(
                    formData[controlAttr.apiname],
                    dependField.dependfieldid,
                    formData
                  );
                }
              }
            });
          });
        }
      }
    },

    // 处理几个对象不让其编辑,返回值设置edit，所以和readonly相反
    checkApiNameAlterEditToFalse(val) {
      //  根据是否只读判断
      if (val.readonly === "1") {
        return false;
      } else {
        switch (val.name) {
          case "loginname": //用户名
            return false;
          case "isusing": //启用
            return false;
          case "role": //角色
            return false;
          case "profile": //简档
            return false;
          case "company": //公司
            return false;
          case "department": //部门
            return false;
          case "title": //职位
            return false;
          case "employeenum": //员工编号
            return false;
          case "alias": //别名
            return false;
          case "division": //分部
            return false;
          case "isteam": //是否开通群策
            return false;
          case "userlicence": //用户许可
            return false;
          case "createby": //创建人
            return false;
          case "lastmodifyby": //最后修改人
            return false;
          case "levels": //用户级别
            return false;
          case "manager": //经理
            return false;
          default:
            return true;
        }
      }
    },
    // 获取展示结构与内容
    getEditPersonalInformation() {
      this.loading = true;
      this.formAttr = [];
      this.optionList = {};
      this.attrIndex = -1;
      editPersonalInformation().then((res) => {
        this.loading = false;
        //  return
        if (res.result) {
          this.currencyList = res.data.currencyList;
          res.data.fieldOrder.forEach((group, groupIndex) => {
            //  处理几个对象不让其编辑
            group.list.forEach((itemx) => {
              // 添加是否可编辑字段
              itemx.fieldProperty.edit = this.checkApiNameAlterEditToFalse(
                itemx.fieldProperty
              );
            });
            // 名称展示
            this.attrIndex = -1;
            this.formAttr.push({
              width: "100%",
              colNum: 1,
              type: "groupTitle",
              title: group.name,
              selectionId: groupIndex,
              groupShow: true,
              hidden: false,
            });
            // 插入表单元素项
            group.list &&
              group.list.length > 0 &&
              group.list.forEach((line) => {
                this.attrIndex += 1;
                //  添加属性
                // 用户名单独处理
                if (line.fieldProperty.entityFieldName === "name") {
                  this.attrIndex += 1;
                  line.layered &&
                    line.layered.forEach((item) => {
                      // 判断是否可编辑
                      item.edit = item.readonly !== "1";
                      this.addFormAttr(line.id, group.cols, item, undefined);
                    });
                } else {
                  this.addFormAttr(
                    line.id,
                    group.cols,
                    line.fieldProperty,
                    undefined
                  );
                }
              });
          });

          // 强制刷新表单
          this.$refs.baseFormDetail &&
            this.$refs.baseFormDetail.filterFormData();
          // 依赖关系，绑定事件
          this.controlContainer = JSON.parse(res.data.controlContainer);
          this.setControlContainer();
        }
      });
    },
    // 改变查找/查找带值选项及值 1
    changeSelect(row, fieldId, formData) {
      let options = [];
      let fieldVal = [];
      if (row instanceof Array) {
        row.forEach((ele) => {
          options.push({
            label: ele.name,
            value: ele.id,
          });
        });
      } else {
        options.push({
          label: row.data.name,
          value: row.data.id,
        });
      }

      this.formAttr.forEach((attr) => {
        if (attr.fieldId === fieldId) {
          // 防止清空时清空选项，再次编辑查找字段时显示数据Id
          if (options.length > 0) {
            this.$set(this.optionList, attr.prop, options);
          }
          if (attr.fieldType === "MR") {
            options.forEach((option) => {
              fieldVal.push(option.value);
            });
            formData[attr.name] = fieldVal;
          } else {
            formData[attr.name] = row.data === undefined ? "" : row.data.id;
          }
        }
      });
    },
    addFieldOption(prop, option) {
      if (this.optionList[prop]) {
        this.optionList[prop].push(option);
      } else {
        this.optionList[prop] = [option];
      }
    },
    // 远程搜索
    remoteMethod(params, item, formData) {
      //在项目管理系统下对负责人所属项目的判断
      if (
        item.name == "principal" ||
        item.name == "member" ||
        (window.location.href.substring(window.location.href.length - 3) ==
          "p04" &&
          (item.name == "milestone" || item.name == "affected_milestones"))
      ) {
        if (
          formData.project_name == "" ||
          formData.their_project == "" ||
          formData.their_project_name == ""
        ) {
          this.$message.error("请选择项目");
          return;
        }
      }
      if (
        ((window.location.href.substring(window.location.href.length - 3) ==
          "p03" ||
          this.getCookie("parentObjectApi") == "cloudccTask") &&
          this.milestone == "their_milestone") ||
        (this.getCookie("parentObjectApi") == "cloudccTask" &&
          this.milestone == "their_task")
      ) {
        params.projectId = "";
      } else if (
        (window.location.href.substring(window.location.href.length - 3) ==
          "p05" ||
          this.getCookie("parentObjectApi") == "cloudccSubtask") &&
        this.milestone == "their_task"
      ) {
        params.projectId = "";
      } else {
        params.projectId =
          formData.their_project ||
          formData.project_name ||
          formData.their_project_name ||
          "";
      }
      // 查询是否有默认条件
      CommonObjApi.getLookupInfo({
        fieldId: params.fieldId,
        objId: item.objId,
        prefix: item.prefix,
      }).then((res) => {
        // eslint-disable-next-line no-unused-vars
        // let lkdp = [""];
        let values = [''];

        // 开启查找筛选
        if (res.data.isEnableFilter === "true") {
          for (let reltaion in res.data.filterFieldIds) {
            let attr = this.formAttr.find((attrItem) => {
              return (
                attrItem.fieldId === res.data.filterFieldIds[reltaion].fieldid
              );
            });
            if (
              attr !== undefined &&
              formData[attr.prop] !== null &&
              Array.isArray(formData[attr.prop])
            ) {
              values.push(formData[attr.prop].join(";"));
            } else if (attr !== undefined && formData[attr.prop] !== null) {
              values.push(formData[attr.prop]);
            }
          }
        }
        params.lkdp = JSON.stringify(values);

        CommonObjApi.queryMoreDataByName(params).then((res) => {
          let options = JSON.parse(res.data);
          options.forEach((option) => {
            option.value = option.id;
            option.label = option.queryname;
          });
          this.$set(this.optionList, item.prop, options);
        });
      });
    },
    // 新增查找、查找多选字段
    newSearchableField(item) {
      // 获取对象api后进行页面跳转
      CommonObjApi.getLookupInfo({
        fieldId: item.fieldId,
        objId: item.lookupObjid,
        prefix: item.lookupObj,
      }).then((res) => {
        let objectApi = res.data.objectApi;
        window.open(
          `#/add-relevant-obj/${item.prefix}/${objectApi}/${this.newObjLevel}?addType=option&prop=${item.prop}`
        );
      });
    },
    // 取消编辑
    cancelChangeDetail() {
      this.getEditPersonalInformation();
    },
    // 保存编辑
    saveChange() {
      this.$refs.baseFormDetail.$refs.elForm.validate((valid) => {
        if (valid) {
          let dataList = [];
          let data = Object.assign({}, this.$refs.baseFormDetail.formData);
          // 数字字段特殊处理
          // for (const key in data) {
          //   this.formAttr.forEach((attr) => {
          //     if (
          //       attr.prop === key &&
          //       data[key] === undefined &&
          //       (attr.type === 'number' || attr.type === 'percent-number')
          //     ) {
          //       data[key] = ''
          //     }
          //   })
          // }
          delete data.undefined;

          // 使用有效的分配规则进行分配
          for (const key in data) {
            // 修改查找多选和选项列表数据格式
            if (Array.isArray(data[key])) {
              data[key] = data[key].join(";");
              // "--无--"
            } else if (data[key] === this.$i18n.t("select.nullvalue")) {
              this.formAttr.forEach((attr) => {
                if (
                  attr.prop === key &&
                  (attr.type === "select" ||
                    attr.type === "multi-select" ||
                    attr.type === "remote-select" ||
                    attr.type === "remote-multi-select")
                ) {
                  data[key] = "";
                }
              });
            } else if (data[key] !== undefined) {
              // 所有数据均变为字符串保存
              if (data[key] !== undefined) {
                data[key] = data[key].toString();
              } else {
                data[key] = "";
              }
            } else if (data[key] === undefined) {
              this.formAttr.forEach((attr) => {
                if (attr.prop === key && attr.fieldType === "P") {
                  data[key] = "";
                }
              });
            }
          }
          data.id = this.id;
          dataList.push(data);
          let that = this;
          // 判断结束时间是否大于开始时间
          if (
            (dataList[0].begintime && dataList[0].endtime) ||
            (dataList[0].start_date && dataList[0].end_date)
          ) {
            // eslint-disable-next-line no-inner-declarations
            function tab(date1, date2) {
              let oDate1 = new Date(date1);
              let oDate2 = new Date(date2);
              if (oDate1.getTime() > oDate2.getTime()) {
                // 结束日期不得小于开始日期
                that.$message.warning(
                  that.$i18n.t("label.weixin.end.later.start")
                );
                return false;
              } else {
                return true;
              }
            }
            if (
              !tab(dataList[0].begintime, dataList[0].endtime) ||
              !tab(dataList[0].start_date, dataList[0].end_date)
            ) {
              return false;
            }
          }
          // 项目管理系统下，fieldType为c即金额类添加验证
          if (
            this.$store.state.home.homeApplicationId === "ace2020D253B3984Or6Q"
          ) {
            let moneyFlag = true,
              moneyName = "";
            this.formAttr.forEach((item) => {
              if (item.fieldType && item.fieldType === "c") {
                if (
                  dataList[0][item.name] &&
                  dataList[0][item.name].length > 10
                ) {
                  moneyName = item.fieldLabel;
                  moneyFlag = false;
                }
              }
            });
            if (!moneyFlag) {
              // 最多输入10位
              this.$message.warning(
                `${moneyName}` +
                  this.$i18n.t("label.projectManagement.enter.up.to.10.digits")
              );
              return;
            }
          }

          //
          this.loading = true;
          savePersonalInformation(JSON.stringify(dataList[0]))
            .then((res) => {
              this.loading = false;
              localStorage.setItem("changeUserInfo", new Date());
              if (res.data && res.data.result === "true") {
                this.$message.success(this.$i18n.t("savesuccess"));
                // 更新多语言等
                location.reload();
              } else {
                this.$message.error(this.$i18n.t("chatter_save_f"));
                // 刷新数据
                this.getEditPersonalInformation();
              }
            })
            .catch(() => {
              this.loading = false;
            });
        } else {
          // 存在验证错误，请检查填写的内容
          this.$message.warning(
            this.$i18n.t("vue_label_commonobjects_detail_check_the_content")
          );
        }
      });
    },
    // 增加表单信息
    addFormAttr(selectionId, colNum, attr, isChildren, position) {
      if (position !== undefined) {
        this.$set(attr, "position", position);
      }
      this.$set(attr, "selectionId", selectionId);
      attr.required = attr.required === "1";
      if (attr.required && attr.edit) {
        attr.rules = [
          {
            required: attr.required,
            message: this.$i18n.t("CloudCCCRM_ST_Info_TypeIn_001") + attr.label,
            trigger: "blur",
          },
        ];
      }

      // attr.name = attr.entityFieldName
      // attr.prop = attr.name
      // 货币
      if (attr.name === "currency") {
        attr.type = "L";
      }
      this.attrIndex += colNum === 1 ? 0 : 1;
      // name统一使用entityFieldName
      this.$set(attr, "name", attr.entityFieldName);
      this.$set(attr, "prop", attr.entityFieldName);
      this.$set(attr, "attrIndex", this.attrIndex);
      this.$set(attr, "label", attr.label);
      this.$set(attr, "fieldType", attr.type);
      this.$set(attr, "fieldId", attr.id);
      this.$set(attr, "objId", attr.lookupObjid);
      this.$set(attr, "prefix", attr.lookupObj);
      this.$set(attr, "type", INPUTTYPE[attr.type]);
      // 百分数字段字数长度限制
      if (attr.fieldType === "P") {
        attr.precision = Number(attr.decimalPlaces);
        let decimal =
          attr.decimalPlaces !== undefined && attr.decimalPlaces !== null
            ? 1 / Math.pow(10, Number(attr.decimalPlaces))
            : 0;
        if (attr.fieldLength !== undefined) {
          attr.valueInterval = {
            min:
              0 -
              Math.pow(10, attr.fieldLength - attr.decimalPlaces - 1) +
              decimal,
            max:
              Math.pow(10, attr.fieldLength - attr.decimalPlaces - 1) - decimal,
          };
        } else {
          attr.valueInterval = {
            min:
              0 - Math.pow(10, attr.length - attr.decimalPlaces - 1) + decimal,
            max: Math.pow(10, attr.length - attr.decimalPlaces - 1) - decimal,
          };
        }
      }
      // 图片显示
      if (
        attr.fieldType === "IMG" &&
        attr.expressionType !== "url" &&
        attr.value !== undefined &&
        this.operation === "EDIT"
      ) {
        if (attr.value !== "") {
          attr.data = [
            {
              name: "",
              url: `${this.userUrl}/querysome.action?m=viewImg&fileid=${attr.value}&binding=${this.token}`,
            },
          ];
        } else {
          attr.data = [];
        }
      }
      // 评分+1
      if (attr.type === "score") {
        attr.value = Number(attr.value);
      }
      // 处理下拉项
      if (attr.type === "select" || attr.type === "multi-select") {
        let options = [];

        attr.selectOptions &&
          attr.selectOptions.length > 0 &&
          attr.selectOptions.forEach((item) => {
            options.push({
              key: item.label,
              val: item.value,
            });
          });

        this.optionList[attr.prop] = options;
        // 货币类型
        if (attr.name === "currency") {
          let list = [];
          this.currencyList.forEach((item) => {
            list.push({ key: item.currencyName, val: item.currencyCode });
          });
          this.optionList[attr.name] = list;
        }

        // 记录类型选项特殊处理
        // if (attr.prop === "recordtype") {
        //   this.recordType = attr.id;
        //   this.optionList[attr.prop] = [
        //     {
        //       key: attr.value,
        //       val: attr.id,
        //     },
        //   ];
        // }
        // else {
        //   this.recordType = attr.value;
        //   // this.optionList[attr.prop] = this.recordOptions;
        // }
        // 选项列表（多选）
        if (attr.type === "multi-select") {
          // "--无--"
          attr.value =
            attr.value === this.$i18n.t("select.nullvalue") || attr.value === ""
              ? []
              : attr.value.split(";");
        }
      } else if (
        attr.type === "remote-select" ||
        attr.type === "remote-multi-select"
      ) {
        // 查找多选字段选项设置
        if (
          attr.fieldType === "MR" &&
          attr.value.length !== 0 &&
          attr.data &&
          attr.data.length > 0 &&
          attr.data[0].value !== ""
        ) {
          let multiOptions = [];
          let labels = attr.data[0].value.split(";");
          let values = attr.data[0].id.split(";");

          for (let index = 0; index < labels.length; index++) {
            multiOptions.push({
              label: labels[index],
              value: values[index],
            });
          }
          this.$set(this.optionList, attr.prop, multiOptions);
          // "--无--"
          attr.value =
            attr.value === this.$i18n.t("select.nullvalue") ||
            attr.data[0].value === ""
              ? []
              : attr.value.split(";");
        } else {
          // 查找字段选项设置
          let multiOptions = [];
          // 查找字段对应的记录有对应的value时才展示
          if (
            attr.value !== "" &&
            attr.data &&
            attr.data.length > 0 &&
            attr.data[0].value !== ""
          ) {
            multiOptions.push({
              label: attr.data[0].value,
              value: attr.data[0].id,
            });
          } else if (
            attr.value !== "" &&
            attr.data &&
            attr.data.length > 0 &&
            attr.data[0].value === ""
          ) {
            // 绑定的查找字段被删除后，不展示被删除数据的id
            attr.value = "";
          }

          // 创建人和最后修改人选项
          // if (attr.name === 'createbyid' || attr.name === 'lastmodifybyid') {
          //   multiOptions.push({
          //     value: attr.value,
          //     label: attr[`${attr.name}Name`],
          //   })
          // }
          this.$set(this.optionList, attr.prop, multiOptions);
        }
        attr.readonly = true;
      } else if (attr.type === "number") {
        if (Number(attr.decimalPlaces) !== 0) {
          attr.precision = Number(attr.decimalPlaces);
        }
        attr.valueInterval = {
          min: 0 - Math.pow(10, attr.fieldLength - attr.decimalPlaces - 1) + 1,
          max: Math.pow(10, attr.fieldLength - attr.decimalPlaces - 1) - 1,
        };
      } else if (attr.type === "checkbox") {
        attr.value = attr.value === "true" || attr.value === "1" ? true : false;
      }
      attr.width = colNum === 1 ? "100%" : "50%";
      attr.colNum = colNum;
      // attr.selectionId = selectionId;
      attr.groupShow = true;
      this.formAttr.push(attr);

      if (attr.isContrField) {
        let changeEvent =
          attr.changeEvent === undefined
            ? "control"
            : `${attr.changeEvent},control`;
        this.$set(attr, "changeEvent", changeEvent);
      }
    },

    /**
     * 打开绑定手机号弹框
     */
    openBindMobile(item) {
      // 打开绑定手机号的弹框
      this.bindMobileDialog = true;
      if (item.value) {
        // 有数据则回显
        let mobileArr = item.value.split(" ");
        this.bindMobileForm = {
          mobile: mobileArr[1],
          countryCode: mobileArr[0].slice(1),
        };
        this.mobileTitle = this.$i18n.t("c937");
      } else {
        // 无则赋默认值
        this.bindMobileForm = {
          mobile: "",
          countryCode: "86",
        };
        this.mobileTitle = this.$i18n.t("c954");
      }
    },
    /**
     * 绑定手机号
     */
    handleBindmobile(formName) {
      this.$refs[formName].validate((valid) => {
        if (valid) {
          // 绑定手机号接口
          bindMobile(this.bindMobileForm).then((res) => {
            if (res.result && res.data) {
              if (res.data.result == "true" && res.data.returnInfo) {
                this.$message.success(res.data.returnInfo);
                // 刷新数据
                this.getEditPersonalInformation();
              } else {
                this.$message.error(res.data.returnInfo);
              }
              this.handleCancelBind(formName);
            }
          });
        }
      });
    },
    /**
     * 取消绑定
     */
    handleCancelBind(formName) {
      this.bindMobileDialog = false;
      this.$refs[formName].resetFields();
    },
  },
};
</script>

<style scoped lang="scss">
.changeDetailBox {
  // ::v-deep .el-form-item{
  //   margin-bottom: 10px;
  //   display: flex;
  //   flex-direction: column;
  // }
}
.option_btn_model {
  display: flex;
  justify-content: center;
  margin-bottom: 10px;
}
.download_file_btn {
  margin: 10px 0;
  > a {
    text-decoration: underline;
  }
}
.baseForm {
  ::v-deep .el-form-item__label {
    width: 210px !important;
  }
  ::v-deep .el-form-item__content {
    margin-left: 210px !important;
    // .el-input {
    //   width: 100% !important;
    // }
  }
  // ::v-deep .remoteBtn{
  //   right: 8px;
  // }
}
</style>
