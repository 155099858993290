<!-- 开发人员：马金祥 -->
<template>
  <div>
    <el-popover
      placement="bottom-start"
      v-model="visible"
      width="400"
      trigger="click"
      @hide="hidePopover"
      @show="showPopover"
    >
      <el-tabs
        v-model="activeTab"
        @tab-click="handleTabClick"
        class="tabContainer"
      >
        <!-- 任务 -->
        <el-tab-pane
          :label="$t('label.chatter.task')"
          name="task"
        ></el-tab-pane>
        <!-- 问题 -->
        <el-tab-pane
          :label="$t('label.tabpage.issuez')"
          name="issue"
        ></el-tab-pane>
      </el-tabs>
      <div class="vue-dropdown default-theme">
        <div class="search-module clearfix">
          <el-input
            class="search-text"
            v-model="searchValue"
            :placeholder="placeholder"
            @input="searchList"
            @keyup.enter.native="searchList"
            suffix-icon="el-icon-search"
          ></el-input>
          <!-- <span class="el-icon-search search-icon" @click="searchList"></span> -->
        </div>
        <ul class="list-module" v-show="show && !loading">
          <li v-for="(item, index) in itemlist" :key="index">
            <template v-if="item.childTask">
              <p class="milestoneName" v-if="item.milestoneName">
                <svg aria-hidden="true" class="img">
                  <use href="#icon-milestone"></use>
                </svg>
                <span>{{ item.milestoneName }}</span>
              </p>
              <template v-if="item.childTask.length">
                <p
                  v-for="(childItem, key) in item.childTask"
                  :key="key"
                  class="childItem"
                >
                  <!-- 任务 -->
                  <el-radio
                    @change="itemClick(childItem, 1)"
                    v-model="selectId"
                    class="itemOne taskItem"
                    v-if="childItem.taskName"
                    :label="childItem.taskId"
                    >{{ childItem.taskName }}</el-radio
                  >
                  <!-- 子任务 -->
                  <template
                    v-if="
                      childItem.childSubTask && childItem.childSubTask.length
                    "
                  >
                    <el-radio
                      @change="itemClick(subItem, 2)"
                      v-model="selectId"
                      :label="subItem.subTaskId"
                      v-for="(subItem, subkey) in childItem.childSubTask"
                      class="itemOne subItem"
                      :key="subkey"
                      >{{ subItem.subTaskName }}</el-radio
                    >
                  </template>
                </p>
              </template>
            </template>
            <template v-else>
              <el-radio
                @change="itemClick(item, 3)"
                v-model="selectId"
                :label="item.id"
                >{{ item.name }}</el-radio
              >
            </template>
          </li>
        </ul>
        <div class="tip_load el-icon-loading" v-show="loading"></div>
        <div class="tip_nodata" v-show="!show && !loading">
          {{ nodatatext }}
        </div>
      </div>
      <div slot="reference" class="downIcon">
        <span>{{ selectVal }}</span>
        <i class="el-icon-search"></i>
      </div>
    </el-popover>
  </div>
</template>

<script>
import debounce from "lodash.debounce";
//api
import * as projectApi from "./api";
export default {
  data() {
    return {
      itemlist: [],
      show: true,
      searchValue: "",
      loading: false,
      selectVal: "",
      selectId: "",
      visible: false,
      activeTab: "task",
      activeTabNow: 1, //记录用户点击的tab
    };
  },
  props: {
    placeholder: {
      type: String,
      default() {
        return this.$i18n.t("label.searchs");
      },
    },
    nodatatext: {
      type: String,
      default() {
        return this.$i18n.t("vue_label_SMS_nodata");
      },
    },
    project_name: {
      type: String,
      default: "",
    },
    selectValue: {
      type: String,
      default: "",
    },
    operationColum: {
      //当前操作的行，实际工作清单批量新增使用
      type: Number,
    },
     member:{
      type:String,
      default:""
    }
  },
  watch: {
    itemlist: {
      immediate: true,
      handler(val) {
        this.show = val.length;
      },
    },
    visible(val) {
      if (!val) {
        // 关闭浮窗
        this.searchValue = "";
        // this.activeTab = "task";
        this.loading = false;
      }
    },
    project_name(val) {
      //解决用户先点击出现浮窗，又清空了项目名称
      this.selectId = "";
      this.selectVal = "";
      this.$emit("update:projectDataType", this.activeTabNow);
      this.$emit("update:taskOrProblem", this.selectId);
      if (!val) {
        this.visible = false;
      }
    },
    // 监听当前选中的任务/问题，父组件使用
    selectId(val) {
      this.$emit("checkSearchTab", val, this.operationColum); //批量新增时使用
      this.$emit("update:projectDataType", this.activeTabNow);
      this.$emit("update:taskOrProblem", this.selectId);
    },
    // 监听父组件传值--已选中的任务/问题
    selectValue: {
      handler(val) {
        if (val) {
          this.getPrimaryList(); //编辑时加载数据
        }
      },
      immediate: true,
    },
  },
  methods: {
    hidePopover() {
      this.$emit("update:projectDataType", this.activeTabNow);
      this.$emit("update:taskOrProblem", this.selectId);
      if (this.project_name) {
        //存在项目名称时才触发
        this.$parent.$emit("el.form.blur");
      }
    },
    showPopover() {
      if (this.project_name) {
        this.getDownList(); //项目名称改变时加载数据
        this.visible = true;
      } else {
        this.visible = false;
        this.$message.error(this.$i18n.t("label.projectManagement.selectitem")); //请选择项目
      }
    },
    // tab切换点击
    handleTabClick() {
      this.searchValue = "";
      this.loading = true;
      this.getDownList();
    },
    itemClick(item, type) {
      this.activeTabNow = type; //点击时记录用户操作tab，避免activeTab被赋为初始值
      if (type === 1) {
        this.selectVal = item.taskName;
        this.selectId = item.taskId;
      } else if (type === 2) {
        this.selectVal = item.subTaskName;
        this.selectId = item.subTaskId;
      } else if (type === 3) {
        this.selectVal = item.name;
        this.selectId = item.id;
      }
      this.visible = false;
      this.searchValue = "";
    },
    searchList: debounce(function () {
      if (this.searchValue) {
        this.getDownList();
      } else {
        this.itemlist = [];
      }
    }, 300),
    // 查询下拉项目数据
    getDownList() {
      this.loading = true;
      this.itemlist = [];
      // 根据不同的类型查询数据
      if (this.activeTab === "task") {
        //里程碑、任务、子任务
        projectApi
          .getMixedInformation({
            projectId: this.project_name,
            taskName: this.searchValue,
            principal:this.member
          })
          .then((res) => {
            if (res.data?.length > 0) {
              this.itemlist = res.data.map((item) => {
                return item;
              });
            }
            this.loading = false;
          })
          .catch(() => {
            this.itemlist = [];
            this.loading = false;
          });
      } else {
        //问题
        projectApi
          .getProblemInformation({
            projectId: this.project_name,
            problemName: this.searchValue,
            principal:this.member
          })
          .then((res) => {
            if (res.data?.length > 0) {
              this.itemlist = res.data.map((item) => {
                return item;
              });
            }
            this.loading = false;
          })
          .catch(() => {
            this.itemlist = [];
            this.loading = false;
          });
      }
    },
    getPrimaryList() {
      // 任务、子任务
      projectApi
        .getMixedInformation({
          projectId: this.project_name,
          taskName: this.searchValue,
          principal:this.member
        })
        .then((res) => {
          if (res.data?.length > 0) {
            let data = [],
              data1 = [];
            for (let item of res.data) {
              if (item.childTask?.length) {
                for (let taskItem of item.childTask) {
                  data.push({
                    name: taskItem.taskName,
                    id: taskItem.taskId,
                  });
                  if (taskItem.childSubTask?.length) {
                    for (let subItem of taskItem.childSubTask) {
                      data1.push({
                        name: subItem.subTaskName,
                        id: subItem.subTaskId,
                      });
                    }
                  }
                }
              }
            }
            // 编辑时选中值-任务
            for (let item of data) {
              if (item.id === this.selectValue) {
                this.activeTab = "task";
                this.activeTabNow = 1;
                this.selectId = item.id;
                this.selectVal = item.name;
              }
            }
            // 编辑时选中值-子任务
            for (let item of data1) {
              if (item.id === this.selectValue) {
                this.activeTab = "task";
                this.activeTabNow = 2;
                this.selectId = item.id;
                this.selectVal = item.name;
              }
            }
          }
        });
      // 问题
      projectApi
        .getProblemInformation({
          projectId: this.project_name,
          problemName: this.searchValue,
          principal:this.member
        })
        .then((res) => {
          if (res.data?.length > 0) {
            // 编辑时选中值
            for (let item of res.data) {
              if (item.id === this.selectValue) {
                this.activeTab = "issue";
                this.activeTabNow = 3;
                this.selectId = item.id;
                this.selectVal = item.name;
              }
            }
          }
        });
    },
  },
  created() {},
  mounted() {},
};
</script>

<style lang="scss" scoped>
p,
ul,
li {
  margin: 0;
}
.downIcon {
  display: flex;
  cursor: pointer;
  height: 30px;
  line-height: 30px;
  word-break: break-all;
  margin-top: 5px;
  align-items: center;
  width: 100%;
  border-radius: 4px;
  border: 1px solid #dcdfe6;
  span {
    display: inline-block;
    height: 30px;
    line-height: 30px;
    padding: 0 30px 0 10px;
    width: 100%;
    color: #606266 !important;
    font-size: 12px;
    overflow: hidden;
  }
  i {
    display: inline-block;
    width: 30px;
    height: 30px;
    line-height: 30px;
    text-align: center;
    color: #c0c4cc;
  }
}
.tabContainer {
  margin: 0 -12px;
  ::v-deep .el-tabs__item {
    margin: -8px 0 0 20px;
    color: #9b9a9a;
    &:last-of-type {
      margin-left: -10px;
    }
    &.is-active {
      color: #080707;
      font-weight: bold;
    }
    &:hover {
      color: #080707;
    }
  }
  ::v-deep .el-tabs__active-bar {
    display: none;
  }
  ::v-deep .el-tabs__nav-wrap::after {
    height: 1px;
  }
}
::v-deep .search-module .el-input.is-active .el-input__inner,
.search-module .el-input__inner:focus {
  border-color: #ffffff;
  outline: 0;
}
::v-deep .search-module .el-input__inner:hover {
  border-color: #ffffff;
}
::v-deep .search-module .el-input__inner {
  height: 20px;
  padding: 0;
  width: 85%;
  border: 0;
}
.vue-dropdown.default-theme {
  overflow: hidden;
  margin: 5px -12px;
  .search-module {
    position: relative;
    border: 1px solid #e6e6e6;
    border-radius: 4px;
    margin: 0 22px 20px;
    .search-text {
      width: 100%;
      height: 30px;
      padding: 5px 20px;
      box-shadow: none;
      border: none;
      outline: none;
      box-sizing: border-box;
      &:focus {
        border: none;
        outline: none;
        box-sizing: border-box;
      }
    }

    .search-icon {
      cursor: pointer;
      position: absolute;
      top: 24%;
      right: 20px;
      color: #aaa;
    }
  }

  .list-module {
    max-height: 200px;
    overflow-y: auto;
    padding: 0 20px;
    color: #333;
    li {
      &.active {
        color: #00a0e9;
      }
      &._self-hide {
        display: none;
      }
      padding: 5px 0;
      &:hover {
        cursor: pointer;
      }
    }
    .itemOne {
      display: block;
      margin-top: 8px;
    }
    .subItem {
      display: block;
      margin-left: 20px;
    }
    .taskItem {
      font-weight: bold;
    }
    .milestoneName {
      font-weight: bold;
      font-size: 14px;
      margin-bottom: 10px;
      display: flex;
      .img {
        margin-right: 10px;
        width: 16px;
        height: 16px;
      }
    }
    ::v-deep .el-radio__label {
      font-size: 12px;
    }
  }
}
.tip_nodata {
  font-size: 12px;
  padding-left: 20px;
}
.tip_load {
  width: 100%;
  font-size: 16px;
  text-align: center;
  padding: 20px 0;
}
</style>
