// 我的个人信息接口文件
import axios from "@/config/httpConfig";

// 更改密码保存
export function userPwModify(data) {
    return axios.post("/accessApi/login/userPwModify", data);
}
// 获取密码策略
export function getPasswordPolicy() {
    return axios.post("/accessApi/login/getPasswordPolicy");
}
// 获取 访问权限持续时间
export function adminAccessSetup() {
    return axios.post("/accessApi/login/adminAccessSetup");
}
// 保存访问权限持续时间
export function adminAccessSetupSave(data) {
    return axios.post("/accessApi/login/adminAccessSetupSave", data);
}
// 获取用户最近十次的登录历史
export function getLoginHistory() {
    return axios.post("/accessApi/login/getLoginHistory");
}
// 获取编辑的个人信息
export function editPersonalInformation() {
    return axios.post("/accessApi/login/editPersonalInformation");
}
// 获取隐藏/不隐藏类型的字段html标签
export function getHiddenOrNotFieldHTML() {
    return axios.post("/accessApi/login/getHiddenOrNotFieldHTML");
}
// 保存个人信息
export function savePersonalInformation(data) {
    return axios.post("/accessApi/login/savePersonalInformation", data);
}
// 绑定手机号
export function bindMobile(data) {
    return axios.post("/MobileApi/bindMobile", data);
}

// 获取查找信息
export function getLookupInfo(data) {
    return axios.post('/lookup/getLookupInfo', data)
}

// 根据名称搜索对象记录接口--查找字段自动搜索
export function queryMoreDataByName(data) {
    return axios.post('objectdetail/queryMoreDataByName', data)
}
//查询当前用户是否已绑定手机号
export function isBindMobile(data) {
    return axios.post("/MobileApi/isBindMobile",data);
}
//修改首次进入个人信息编辑页面
export function changeFirstOpenPerInfo(data) {
    return axios.post("/MobileApi/changeFirstOpenPerInfo",data);
}
