<!-- 查找/查找多选弹框 -->
<template>
  <div>
    <!-- 查找条件 -->
    <base-form
      ref="baseForm"
      :form-attr="formAttr"
      :label-width="labelWidth"
      v-if="formAttr && formAttr.length > 0"
    >
    </base-form>
    <!-- 无搜索条件时展示模糊搜索框 -->
    <el-input v-else v-model="searchKeyWord" class="searchKeyWord-input">
    </el-input>
    <!-- 按钮 -->
    <div style="margin-bottom: 10px">
      <el-button size="mini" type="primary" @click="search">
        <!-- 搜索 -->
        {{ $t("label.searchs") }}
      </el-button>
      <el-button size="mini" type="primary" @click="clearSelect" v-if="isShowClearBtn">
        <!-- 清空 -->
        {{ $t("label_emailtocloudcc_clear") }}
      </el-button>
      <el-button
        size="mini"
        type="primary"
        v-if="checked"
        @click="selectChecked"
      >
        <!-- 选择 -->
        {{ $t("label.select") }}
      </el-button>
      <el-button
        size="mini"
        type="primary"
        v-if="relevantObjid !== 'user' && isquickcreated"
        @click="create"
      >
        <!-- 新建 -->
        {{ $t("label.new") }}
      </el-button>
    </div>
    <!-- 列表 -->
    <table-panel
      class="text-center"
      ref="tablePanel"
      :border="true"
      :show-lock="false"
      :table-height="tableHeight"
      :show-index="false"
      :checked="checked"
      :pict-loading="pictLoading"
      :table-attr="tableAttr"
      :page-obj="pageObj"
      :noDataTip="noDataTip"
      :use-hyperlinks="false"
      @append="append"
      @selectData="select"
    >
    </table-panel>
  </div>
</template>

<script>
import { TablePanel } from "@/components/index";
import BaseForm from "./base-form";
import * as CommonObjApi from "./api";
import INPUTTYPE from "@/config/enumCode/searchTableInputType";

export default {
  name: "SearchTable",
  components: { TablePanel, BaseForm },
  props: {
    // 查找字段的id
    fieldId: {
      type: String,
      default: "",
    },
    // 用户类型
    ownerType: {
      type: String,
      default: "",
    },
    // 查找对象的id
    relevantObjid: {
      type: String,
      default: "",
    },
    // 查找对象的api
    relevantObjapi: {
      type: String,
      default: "",
    },
    // 查找对象的前缀
    relevantPrefix: {
      type: String,
      default: "",
    },
    // 查找的数据是否支持多选
    checked: {
      type: Boolean,
      default: false,
    },
    // 主从录入时 记录当前数据所在行数用
    ind: {
      type: Number,
      default: 0,
    },
    projectId: {
      type: String,
      default: "",
    },
    milestone: {
      type: String,
      default: "",
    },
    relevantmodelid:{
      type: String,
      default: "",
    },
    //是否显示清空按钮
    isShowClearBtn:{
      type:Boolean,
      default:true
    }
  },
  data() {
    return {
      prefix: "",
      objectApi: "",
      tableHeight: 400,
      objId: this.$route.params.objId,
      tableAttr: null,
      pageObj: {
        dataList: [],
      },
      pictLoading: false,
      noDataTip: false,
      isSLookup: null,
      formAttr: null,
      labelWidth: 130,
      page: 1, //页码默认为1
      pageSize: 20, // 每页展示数据条数
      searchKeyWord: "",
      selectValue: "",
      searchConditions: "",
      filterConditionVals: [""],
      recordType: "",
      recordOptions: [],
      level: 0,
      isquickcreated: false, // 是否支持快速新建
    };
  },
  created() {
    var _this = this;
    document.onkeydown = function () {
      //按下回车提交
      let key = window.event.keyCode;
      //事件中keycode=13为回车事件
      if (key == 13) {
        _this.search();
      }
    };
  },
  beforeDestroy() {
    window.removeEventListener("storage", this.listenStorageFn);
  },
  methods: {
    init() {
      this.page = 1;
      this.formAttr = [];
      this.searchKeyWord = "";
      this.pageObj.dataList = [];

      // 重置level
      this.level =
        this.$route.params.relevantObjectLevel === undefined
          ? 0
          : Number(this.$route.params.relevantObjectLevel) + 1;
      this.listenStorage();
      
      CommonObjApi.getLookupInfo({
        fieldId: this.fieldId,
        objId: this.relevantObjid,
        prefix: this.relevantPrefix,
      }).then((res) => {
        this.objectApi = res.data.objectApi;
        this.prefix = res.data.prefix;
        this.isquickcreated = res.data.add;

        // 开启查找筛选
        if (res.data.isEnableFilter === "true") {
          this.filterConditionVals = [""];
          this.$emit("setFieldReltaion", res.data.filterFieldIds);
        }
        this.isSLookup = res.data.isSLookup;
        if (res.data.Fields) {
          // 
          this.getFormAttr(res.data.Fields);
        } else {
          this.formAttr = [];
        }
        this.getLookupData();
      });
    },
    // 搜索
    search() {
      this.page = 1;
      this.pageObj.dataList = [];
      let obj = {};
      this.formAttr &&
        this.formAttr.length > 0 &&
        this.formAttr.forEach((item) => {
          obj[item.prop] = this.$refs.baseForm.formData[item.prop];
        });
      this.searchConditions = JSON.stringify(obj);
      this.getLookupData();
    },
    // 滚动加载数据
    append() {
      if (!this.noDataTip) {
        this.getLookupData();
      }
    },
    getCookie(name) {
      var prefix = name + "=";
      var start = document.cookie.indexOf(prefix);
      if (start == -1) {
        return null;
      }
      var end = document.cookie.indexOf(";", start + prefix.length);
      if (end == -1) {
        end = document.cookie.length;
      }
      var value = document.cookie.substring(start + prefix.length, end);
      return unescape(value);
    },
    // 获取查找数据
    getLookupData() {
      if (this.page === 1) {
        this.tableAttr = null;
      }
      this.pictLoading = true;

      /**
       * fieldId	查找字段Id
       * prefix 对象prefix
       * objId 对象id
       * isSLookup 查询方式，true为多条件搜索，false为单条件搜索
       * searchKeyWord 单条件搜索时搜索关键字
       * page 页数
       * pageSize 每页显示条数
       * selectValue 更改所有人时区分用户与伙伴云用户，用户为user,伙伴云用户为partneruser
       * searchConditions 多条件搜索时搜索参数集合,数据格式为{“字段id1”=”value1”,”字段id2”=”value2”}
       * filterConditionVals 查找筛选器参数集合，数据格式为：[“001wdwsjjisss22”,”张三”,”李四”],第一个参数如果有值就传，没值的话传””,后面参数依次按照filterFields字段顺序获取
       */
      let params = {
        fieldId: this.fieldId,
        prefix: this.prefix || this.relevantPrefix,
        objId: this.relevantObjid,
        modelid:this.relevantmodelid,
        isSLookup: this.isSLookup,
        searchKeyWord: this.searchKeyWord,
        page: this.page,
        pageSize: this.pageSize,
        selectedValue: this.ownerType,
        searchConditions: this.searchConditions,
        filterConditionVals:
          this.filterConditionVals === ""
            ? this.filterConditionVals
            : JSON.stringify(this.filterConditionVals),
      };
      //在项目管理系统下对负责人所属项目的判断
      if (
        ((window.location.href.substring(window.location.href.length - 3) ==
          "p03" ||
          this.getCookie("parentObjectApi") == "cloudccTask") &&
          this.milestone == "their_milestone") ||
        (this.getCookie("parentObjectApi") == "cloudccTask" &&
          this.milestone == "their_task")
      ) {
        params.projectId = "";
      } else if (
        (window.location.href.substring(window.location.href.length - 3) ==
          "p05" ||
          this.getCookie("parentObjectApi") == "cloudccSubtask") &&
        this.milestone == "their_task"
      ) {
        params.projectId = "";
      } else {
        params.projectId = this.projectId;
      }
      params.filterConditionVals =
        params.filterConditionVals === "[null]"
          ? '[""]'
          : params.filterConditionVals;

      CommonObjApi.getLookupData(params).then((res) => {
        if (res.result && res.returnCode === "1") {
          // 页码为1时重新请求表头
          if (this.page === 1) {
            this.tableAttr = res.data.headLabel;
            this.tableAttr.forEach((item) => {
              // 名称、查找、主详、查找多选均可跳转
              if (
                item.schemefieldName === "name" ||
                item.schemefieldType === "Y" ||
                item.schemefieldType === "M" ||
                item.schemefieldType === "MR"
              ) {
                item.click = "selectData";
              }
            });
            this.$refs.tablePanel.changeTableAttr(this.tableAttr);
          }

          this.totalSize = res.data.totalCount;
          if (res.data.dataList.length === 0 && params.page === 1) {
            this.pageObj.dataList = [];
          } else if (this.pageObj.dataList.length === 0 || params.page === 1) {
            if (res.data.dataList.length > this.pageSize) {
              this.pageObj.dataList = res.data.dataList.slice(
                0,
                res.data.dataList.length - 1
              );
            } else {
              this.pageObj.dataList = res.data.dataList;
            }
            this.page++;
          } else {
            // 记忆选中数据
            let checkedList = this.$refs.tablePanel.getCheckedList();
            if (res.data.dataList.length > this.pageSize) {
              this.pageObj.dataList = [
                ...this.pageObj.dataList,
                ...res.data.dataList.slice(0, res.data.dataList.length - 1),
              ];
            } else {
              this.pageObj.dataList = [
                ...this.pageObj.dataList,
                ...res.data.dataList,
              ];
            }
            this.pageObj.dataList.forEach((element) => {
              checkedList.forEach((item) => {
                if (item.id === element.id) {
                  element.checked = true;
                }
              });
            });
            this.page++;
            this.$refs.tablePanel.dataList = this.pageObj.dataList;
          }
          // 判断是否有更多数据
          if (Number(res.data.dataList.length) === this.pageSize) {
            this.noDataTip = false;
          } else {
            this.noDataTip = true;
          }
          this.pictLoading = false;
          this.pageObj.totalSize = res.data.totalCount;
        } else {
          this.pictLoading = false;
          this.tableAttr = [];
          this.pageObj.totalSize = 0;
          this.pageObj.dataList = [];
          this.$message.error(res.stackTrace);
        }
      });
    },
    getFormAttr(attrs) {
      this.formAttr = [];
      attrs.forEach((item) => {
        item.prop = item.id;
        this.$set(item, "width", "50%");
        this.$set(item, "readonly", false);
        if (item.type === "Y" || item.type === "MR") {
          this.$set(item, "type", "input");
        } else {
          this.$set(item, "type", INPUTTYPE[item.type]);
        }
        // 百分数字段字数长度限制
        if (item.type === "number") {
          // item.precision = Number(item.decimalPlaces);
          item.valueInterval = {
            min: 0 - Math.pow(10, 18) + 1,
            max: Math.pow(10, 18) - 1,
          };
        }
        if (item.type === "select" || item.type === "multi-select") {
          item.options = [];
          item.selectOptions &&
            item.selectOptions.forEach((select) => {
              item.options.push({
                key: select.value,
                val: select.value,
              });
            });
        }
        // 如果是报价单明细，特殊处理
        if (item.name === "bjdmc") {
          item.type = "input";
        }
        this.formAttr.push(item);
      });
    },
    // 选中数据
    select(row) {
      this.$emit("changeSelect", row);
    },
    // 多行数据
    selectChecked() {
      let rows = this.$refs.tablePanel.getCheckedList();
      if (rows.length > 0) {
        this.$emit("changeSelect", rows);
      } else {
        // 请选择要添加的数据
        this.$message.warning(
          this.$i18n.t("vue_label_commonobject_view_notice_selectadd")
        );
      }
    },
    // 新建数据
    create() {
      // 传参：对象前缀、对象api
      let objApi = this.relevantObjapi ? this.relevantObjapi : this.objectApi;
      window.open(
        `#/add-relevant-obj/${this.relevantPrefix}/${objApi}/${this.level}`
      );
    },
    // 清空选中数据
    clearSelect() {
      this.$emit("changeSelect", []);
    },
    cancel() {
      this.$emit("cancel");
    },
    listenStorage() {
      window.addEventListener("storage", this.listenStorageFn);
    },
    listenStorageFn(event) {
      // 监听relevantObjectLevel变化
      if (event.key === "relevantObjectLevel") {
        let objectApi = localStorage.getItem("relevantObjectApi");
        let level = localStorage.getItem("relevantObjectLevel");
        if (level - 1 === this.level && objectApi === this.objectApi) {
          this.page = 1;
          this.pageObj.dataList = [];
          this.getLookupData();
          localStorage.setItem(
            "relevantObjectLevel",
            Number(localStorage.getItem("relevantObjectLevel")) - 1
          );
        }
      }
    },
  },
  watch: {
    recordType() {
      this.$nextTick(() => {
        this.pageObj.dataList = [];
        this.getLookupInfo();
      });
    },
  },
};
</script>

<style lang="scss" scoped>
.searchKeyWord-input {
  width: 50%;
  margin-bottom: 10px;
}

::v-deep .el-input__inner {
  height: 34px;
}

::v-deep .el-form-item {
  margin-bottom: 10px;
}
</style>
