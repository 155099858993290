<template>
  <div>
    <!-- 第一个卡片 -->
    <el-card class="card_right_bottom">
      <!-- 标题 -->
      <!-- 个人信息 -->
      <div class="card_right_bottom_title">
        {{ $t("label.pc.ui.2018.personalinfo") }}
      </div>
      <!-- 第一个盒子 -->
      <div class="box1">
        <edit-form  ref='editform'  />
      </div>
      <!-- 手机绑定弹框 -->
      <!-- <el-dialog
        class="phonedoalog"
        :title="$t('label.callboard')"
        :visible.sync="dialogVisible"
        width="37.3%"
        :before-close="handleClose"
        append-to-body
      >
        <div class="mingtitle">
          <svg class="icon" aria-hidden="true">
            <use href="#icon-play_red"></use>
          </svg>
          <dev>
            <p class="hintone">{{ $t("front-setting-dialog-title-one") }}</p>
            <p class="hinttwo">{{ $t("front-setting-dialog-title-two") }}</p>
          </dev>
        </div>
        <el-form
          :model="ValidateForm"
          ref="ValidateForm"
          label-width="auto"
          :rules="rules"
        >
          <el-form-item :label="$t('label.department.phone')" prop="phone">
            <el-input
              v-model="ValidateForm.phone"
              autocomplete="off"
              :placeholder="$t('front-setting-phone-message')"
            ></el-input>
          </el-form-item>
        </el-form>
        <span slot="footer" class="dialog-footer">
          <el-button @click="handleClose">{{$t('label.tabpage.cancel')}}</el-button>
          <el-button type="primary" @click="bindmobile('ValidateForm')">{{
            $t("label.weixin.binding")
          }}</el-button>
        </span>
      </el-dialog> -->
    </el-card>
  </div>
</template>

<script>
/**
 * 个人信息
 */
import editForm from "./editForm";
import * as profileSetting from "@/views/profileSetting/profileSettingApi.js";
export default {
  components: {
    editForm,
  },
  data() {
    var isphone = (rule, value, callback) => {
      const reg = /[a-zA-Z\s]+/;
      if (!value || reg.test(value)) {
        // 请录入正确的手机格式
        return callback(new Error(this.$i18n.t("front-setting-phone-format")));
      } else {
        callback();
      }
    };
    return {
      dialogVisible: false, //绑定手机的弹框
      ValidateForm: {
        phone: "",
      },
      rules: {
        phone: [{ validator: isphone, trigger: "blur" }],
      },
    };
  },

  methods: {
    isBindMobile(){
      profileSetting.isBindMobile().then((res)=>{
        if(res.result&&res.data){
          if(res.data.bindStatus== '1'){
            this.dialogVisible=false
          }else {
            if(res.data.isFirst&& res.data.isFirst=='0'){
              this.dialogVisible=true
            }else{
              this.dialogVisible=false
            }
            if(res.data.mobile){
              this.ValidateForm.phone=res.data.mobile
            } 
          }
        }
      })
    },
    handleClose() {
      profileSetting.changeFirstOpenPerInfo({type:'pc'}).then((res)=>{
        if(res.result){
          this.dialogVisible = false;
          this. ValidateForm. phone='';
          this.$refs.ValidateForm.resetFields();
        }
      })
    },

    bindmobile(formName) {
      let params = {
        mobile: this.ValidateForm.phone,
      };
      this.$refs[formName].validate((valid) => {
        if (valid) {
          profileSetting.bindMobile(params).then((res) => {
            if (res.result && res.data) {
              this.dialogVisible = false;
              this.ValidateForm.phone = "";
              if(res.data.result=='true' && res.data.returnInfo){
                  this.$message.success(res.data.returnInfo)
                }else{
                  this.$message.error(res.data.returnInfo)
                }
              this.$refs.ValidateForm.resetFields();
              this.$refs.editform.getEditPersonalInformation();
            }
          });
        }
      });
    },
  },
  mounted() {
    // let idd = this.$router.currentRoute.query.id;
    // if (idd && idd == "profileInfo") {
    //   this.isBindMobile()
    // }
  },
};
</script>

<style lang="scss" scoped>
@import "./style/comment";
::v-deep .el-dialog__header {
  height: 70px;
  // background: #2d6cfc;
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 0;
  border-bottom: none;
  .el-dialog__title {
    // font-family: SourceHanSansCN-Bold;
    // font-size: 24px;
    // color: #ffffff;
    color: #000;
    font-weight: normal;
  }
}
::v-deep .el-dialog__headerbtn .el-dialog__close {
  display: none;
}
::v-deep .el-dialog__body {
  padding: 0;
  // .el-form .el-input__inner{
  //   border-left: 0;
  // }
  .el-input-group__prepend{
    background: #fff;
    width: 70px;
  }
  .el-input__icon{
    line-height: 30px;
  }
}
.mingtitle {
  margin: 0;
  height: 40px;
  display: flex;
  align-items: center;
  justify-content: center;
  margin-top: 20px;
  margin-bottom: 30px;
  .icon {
    display: inline-block;
    width: 33px;
    height: 28px;
    object-fit: contain;
    margin-right: 13px;
  }
  .hintone {
    font-family: PingFangSC-Regular;
    font-size: 14px;
    color: #333333;
    letter-spacing: 0.28px;
    text-align: center;
  }
  .hinttwo {
    opacity: 0.7;
    font-family: SourceHanSansCN-Regular;
    font-size: 12px;
    color: #f23c3c;
  }
}
::v-deep .el-dialog__footer {
  border-top: none;
}
::v-deep .el-form {
  width: 90%;
  margin-left: 5%;
  .el-form-item__label {
    font-family: PingFangSC-Regular;
    font-size: 12px;
    color: #333333;
    letter-spacing: 0.28px;
  }
  .el-input__inner {
    font-family: PingFangSC-Regular;
    font-size: 12px;
    letter-spacing: 0.28px;
  }
}
</style>