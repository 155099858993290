<template>
  <!-- 表单 可编辑 -->
  <div class="base-form" :style="{ width: width }">
    <el-form
      v-loading="formData.length"
      :status-icon="statusIcon"
      ref="elForm"
      :model="formData"
      class="clearfix"
      :rules="rules"
      :validate-on-rule-change="false"
      :label-position="labelPosition"
      :disabled="disabled"
    >
      <!-- item.fieldType === undefined 时为地址和地理定位child字段 -->
      <template v-for="item in formAttr">
        <div
          class="pull-left"
          :style="{
            width:
              item.type === 'groupTitle'
                ? '100%'
                : item.colNum === 1
                ? oneColumnWidth
                : twoColumnWidth,
            clear: item.attrIndex % columnNum === 0 ? 'both' : '',
          }"
          v-if="formData && !item.hidden && item.groupShow"
          :key="item.prop"
          :devid="item.fieldId?item.fieldId:item.selectionId"
        >
          <!-- 部分标题 -->
          <p
            class="groupTitle"
            v-if="item.type === 'groupTitle' && !item.hidden"
            v-html="item.title"
          ></p>
          <!-- 表单内容项 -->
          <el-form-item
            v-else
            :prop="item.prop"
            :class="[
              item.className,
              { 'no-label': item.noLabel, remind: item.remind },
            ]"
            :style="item.labelWidth ? 'min-height: 38px;' : 'min-height: 38px;'"
            :label-width="item.labelWidth ? item.labelWidth + 'px' : labelWidth"
          >
            <!-- 自定义表单label -->
            <span slot="label">
              <span class="span-box">
                <span>{{ item.label }}</span>
                <el-tooltip
                  popper-class="my-tooltip"
                  class="item"
                  effect="dark"
                  v-show="item.helpText"
                  placement="top-start"
                >
                  <div slot="content" v-html="helpText(item.helpText)"></div>
                  <svg
                    class="icon helpImg"
                    aria-hidden="true"
                    style="display: inline-block"
                  >
                    <use href="#icon-bangzhu-changtai"></use>
                  </svg>
                </el-tooltip>
              </span>
            </span>
            <!-- 富文本组件 -->
            <wang-editor
              v-if="item.type === 'kindeditor' && !item.query"
              :ref="item.name"
              :contents="formData[item.prop]"
              :disabled="!item.edit"
              @editorContent="
                onContentChange(item.changeEvent, arguments[0], item)
              "
            >
            </wang-editor>
            <el-input
              v-if="
                (item.type === 'input' ||
                  item.type === 'text' ||
                  item.type === 'password') &&
                !item.query &&
                !item.filterable
              "
              :readonly="item.entityFieldName == 'mobile'"
              :ref="item.name"
              :disabled="!item.edit"
              :type="item.type"
              :placeholder="item.placeholder"
              :maxlength="
                item.length === null || item.length === undefined
                  ? 1000
                  : Number(item.length)
              "
              auto-complete="off"
              @change="changeEvent(item.changeEvent, arguments[0], item)"
              @blur="
                handleBlurInChild(
                  item,
                  formData[item.prop],
                  formData,
                  ...arguments
                )
              "
              @focus="handleFocusEvent(item)"
              v-model="formData[item.prop]"
              :class="
                userInfo.countryCode !== 'CN'
                  ? false
                  : item.name === 'name' &&
                    (specialObjectapi === 'Account' || objectApi === 'Account')
                  ? 'tianyancha'
                  : item.name === 'twitter'
                  ? 'twitter'
                  : item.name === 'linkedin'
                  ? 'linkedin'
                  : item.name === 'facebook'
                  ? 'facebook'
                  : ''
              "
            >
            </el-input>
            <span
              v-if="
                userInfo.countryCode !== 'CN'
                  ? false
                  : item.name === 'name' &&
                    (specialObjectapi === 'Account' || objectApi === 'Account')
              "
              style="position: absolute"
            >
            </span>

            <!-- 联系人、潜在客户有twitter、linkedin、facebook字段 -->
            <span
              v-if="item.name === 'twitter' ? true : false"
              style="position: absolute"
            >
              <svg class="icon linkedinImg" aria-hidden="true">
                <use
                  :href="
                    isTwitter === false
                      ? '#icon-twitter'
                      : '#icon-twitter_white'
                  "
                ></use>
              </svg>
            </span>
            <span
              v-if="item.name === 'linkedin' ? true : false"
              style="position: absolute"
            >
              <svg class="icon linkedinImg" aria-hidden="true">
                <use
                  :href="
                    isLinkedin === false
                      ? '#icon-Linkedin'
                      : '#icon-Linkedin_blue'
                  "
                ></use>
              </svg>
            </span>
            <span
              v-if="item.name === 'facebook' ? true : false"
              style="position: absolute"
            >
              <svg class="icon linkedinImg" aria-hidden="true">
                <use
                  :href="
                    isFacebook === false
                      ? '#icon-facebook'
                      : '#icon-facebook_white'
                  "
                ></use>
              </svg>
            </span>

            <!-- 评分 -->
            <div :class="`stars ${item.name}`" v-if="item.type === 'score'">
              <span
                v-for="(star, idx) in Number(item.length) + 1"
                v-show="idx !== 0"
                :class="{ show2: idx <= Number(item.value) }"
                @mouseover="setStar(idx, item)"
                @mouseout="setStar('active', item)"
                @click="setClick(item, idx)"
                :key="idx"
              >
              </span>
            </div>
            <!-- 数字、百分比 -->
            <el-input-number
              v-if="
                (item.type === 'number' || item.type === 'percent-number') &&
                !item.query
              "
              :ref="item.name"
              :disabled="!item.edit"
              :type="item.type"
              :controls="false"
              :placeholder="item.placeholder"
              :max="item.valueInterval.max"
              :precision="item.precision || 0"
              controls-position="right"
              @input.native="onInput"
              @blur="
                handleBlurInChild(
                  item,
                  formData[item.prop],
                  formData,
                  ...arguments
                )
              "
              v-model="formData[item.prop]"
            >
            </el-input-number>
            <!--  动态检索项 -->
            <el-autocomplete
              class="el-input"
              v-if="item.query"
              v-model="formData[item.prop]"
              :fetch-suggestions="item.query"
              :placeholder="item.placeholder"
              @select="selecter(item.selecter, ...arguments)"
            >
            </el-autocomplete>
            <!-- ------------------------------------------搜索字段输入框---------------------------------------------- -->
            <!-- 查找/查找多选字段 -->
            <!-- 项目管理系统-新建-实际工作清单菜单-问题/任务字段 -->
            <template v-if="item.name === 'taskOrProblem'">
              <SearchRemoteTab
                :selectValue="item.value || taskOrProblemVal"
                :project_name="formData.project_name"
                :projectDataType.sync="formData.projectDataType"
                :taskOrProblem.sync="formData.taskOrProblem"
                :member="formData.member"
              />
            </template>

            <template v-else>
              <el-select
                v-if="
                  item.type === 'remote-select' ||
                  item.type === 'remote-multi-select'
                "
                v-model="formData[item.prop]"
                filterable
                :placeholder="$t('label.searchs')"
                :disabled="inputCheckIsDisabled(item)"
                :multiple="item.type === 'remote-multi-select' ? true : false"
                clearable
                remote
                :remote-method="(query) => remoteMethod(query, item)"
                :loading="loading"
                class="no-suffix"
                style="width: 100%"
                @blur="blurEvent"
                @change="changeEvent(item.changeEvent, arguments[0], item)"
              >
                <p class="searchTipBox">
                  <span
                    @click="remoteSearch(item)"
                    style="display: inline-block; width: 100%"
                  >
                    <i class="el-icon-search"></i>
                    <span style="padding-left: 10px">
                      <!-- 前往精准搜索 -->
                      {{
                        $t("vue_label_commonobjects_detail_to_precise_search")
                      }}
                    </span>
                  </span>
                </p>
                <el-option
                  v-for="item in optionList[item.prop]"
                  :key="item.value"
                  :label="item.label"
                  :value="item.value"
                >
                </el-option>
                <el-option
                  v-show="false"
                  key="new"
                  :label="$t('label.new')"
                  value="新建"
                >
                </el-option>
                <!-- 新建 -->
                <!-- <p
                v-if="selectCheckIsHiddenNewbulid(item)"
                class="searchTipBox"
                @click="newSearchableField(item)"
              >
                <i class="el-icon-plus"></i>
                <span style="padding-left: 10px">

                  {{ $t('label.new') }}
                </span>
              </p> -->
              </el-select>
              <span
                v-if="
                  (item.type === 'remote-select' ||
                    item.type === 'remote-multi-select') &&
                  item.edit
                "
                class="remoteBtn"
                @click="remoteSearch(item)"
              >
                <!-- 公海池所属公海池在有值情况下，不能编辑，并且不显示搜索按钮 -->
                <i
                  class="el-icon-search"
                  v-if="!inputCheckIsDisabled(item)"
                ></i>
              </span>
            </template>
            <!--任务或事件类型的名称及相关项-->
            <!-- 名称 -->
            <div v-if="item.type === 'eventTypeA'" class="event-item">
              <el-select
                class="event-item-l"
                v-model="eventValueA"
                slot="prepend"
                clearable
                :disabled="item.disabled"
                :placeholder="$t('label.select.please')"
                @change="eventChangeA"
              >
                <el-option
                  v-for="item in item.optionItem"
                  :key="item.id"
                  :label="item.label_name"
                  :value="item.prefix"
                >
                </el-option>
              </el-select>
              <div
                class="event-item-r"
                :class="item.disabled === true ? 'dis' : ''"
                @click="item.disabled === true ? '' : checkItem(0, item)"
              >
                {{ eventCheckedObjA.name || $t("label.weixin.click.searchs") }}
              </div>
            </div>
            <!-- 相关项 -->
            <div v-if="item.type === 'eventTypeB'" class="event-item">
              <el-select
                class="event-item-l"
                v-model="eventValueB"
                slot="prepend"
                clearable
                :disabled="item.disabled"
                :placeholder="$t('label.select.please')"
                @change="eventChangeB"
              >
                <el-option
                  v-for="item in item.optionItem"
                  :key="item.id"
                  :label="item.label_name"
                  :value="item.prefix"
                >
                </el-option>
              </el-select>
              <div
                class="event-item-r"
                :class="item.disabled === true ? 'dis' : ''"
                @click="item.disabled === true ? '' : checkItem(1, item)"
              >
                {{ eventCheckedObjB.name || $t("label.weixin.click.searchs") }}
              </div>
            </div>
            <!-- 文件 -->
            <el-input
              v-if="item.type === 'file' && item.fieldType !== 'IMG'"
              v-show="false"
              v-model="formData[item.prop]"
            />
            <span
              v-if="
                item.type === 'file' && item.fieldType !== 'IMG' && item.data
              "
            >
              <span
                v-for="file in item.data"
                :key="file.id"
                @click="downLoadFile(file)"
                style="width: 100%; display: inline-block; cursor: pointer"
              >
                {{ file.name }}.{{ file.fileType || file.type }}
              </span>
            </span>
            <el-button
              v-if="item.type === 'file' && item.fieldType !== 'IMG'"
              :disabled="!item.edit"
              type="primary"
              size="mini"
              @click="addFile(item)"
            >
              <!-- 选择文件 -->
              {{ $t("label.selectfile") }}
            </el-button>
            <el-input
              v-if="
                item.type === 'file' &&
                item.fieldType === 'IMG' &&
                item.expressionType === 'url'
              "
              :ref="item.name"
              :disabled="!item.edit"
              :placeholder="item.placeholder"
              :maxlength="
                item.length === null || item.length === undefined
                  ? 1000
                  : Number(item.length)
              "
              auto-complete="off"
              @change="changeEvent(item.changeEvent, arguments[0], item)"
              @blur="
                handleBlurInChild(
                  item,
                  formData[item.prop],
                  formData,
                  ...arguments
                )
              "
              v-model="formData[item.prop]"
            >
            </el-input>
            <!-- 图片 -->
            <form-upload
              v-if="
                item.type === 'file' &&
                item.fieldType === 'IMG' &&
                item.expressionType !== 'url'
              "
              :disabled="!item.edit"
              :ref="item.name"
              :prop="item.prop"
              :size="fileSize"
              :showMsg="item.showMsg"
              :msg="item.msg"
              multiple
              list-type="picture-card"
              :file-type="
                item.fieldType === 'IMG'
                  ? ['png', 'jpg', 'jpeg']
                  : item.fileType || []
              "
              accept="image/jpeg,image/png"
              :file-list="item.data"
              :beforeUploadFile="beforeUploadFile"
              :fileAttr="item.fileAttr"
              @fileChange="fileChange"
              @remove="remove"
              @success="success"
              @uploadFileSuccess="uploadFileSuccess"
              v-model="formData[item.prop]"
            >
            </form-upload>
            <!-- 长文本 -->
            <el-input
              v-if="item.type === 'textarea'"
              :disabled="!item.edit"
              :type="item.type"
              :placeholder="item.placeholder"
              :maxlength="
                item.length === null || item.length === undefined
                  ? 1000
                  : Number(item.length)
              "
              :autosize="{
                minRows: item.minRows || 3,
                maxRows: item.maxRows || 6,
              }"
              v-model="formData[item.prop]"
              @change="changeEvent(item.changeEvent, arguments[0], item)"
            >
            </el-input>
            <el-radio-group
              v-model="formData[item.prop]"
              v-if="item.type === 'radio'"
              :disabled="!item.edit"
            >
              <span
                v-for="(child, index) in item.options"
                class="plr-10"
                :key="index"
              >
                <el-radio :label="child.val">{{ child.key }}</el-radio>
              </span>
            </el-radio-group>
            <!-- 多选框  用checkedLabel和 checkedKey来区分取值 -->
            <el-checkbox-group
              v-model="formData[item.prop]"
              v-if="item.type === 'checked'"
              :disabled="!item.edit"
            >
              <template v-for="(checkedItem, index) in item.checkedList">
                <el-checkbox
                  :label="checkedItem[item.checkedKey]"
                  :name="item.prop"
                  @change="changeEvent(item.changeEvent, arguments[0], item)"
                  :key="index"
                >
                  {{ checkedItem[item.checkedLabel] }}
                </el-checkbox>
              </template>
            </el-checkbox-group>
            <!-- 下拉框 -->
            <el-select
              v-if="item.type === 'select' || item.type === 'multi-select'"
              v-model="formData[item.prop]"
              :disabled="!item.edit"
              :clearable="
                item.name === 'fulfill_percent' ||
                item.name === 'complete_percentage' ||
                item.name === 'status' ||
                item.name === 'task_status'
                  ? false
                  : true
              "
              :multiple="item.type === 'multi-select' ? true : false"
              :filterable="filterable"
              :placeholder="item.placeholder"
              @visible-change="visibleChange"
              @change="changeEvent(item.changeEvent, arguments[0], item)"
              @blur="types == 'progress' ? selectBlur($event, item) : ''"
              style="width: 100%"
            >
              <template v-for="opt in optionList[item.prop]">
                <el-option :key="opt.key" :label="opt.key" :value="opt.val">
                </el-option>
              </template>
            </el-select>
            <!-- 复选框 -->
            <el-checkbox
              v-if="item.type === 'checkbox'"
              :disabled="!item.edit"
              v-model="formData[item.prop]"
              @change="changeEvent(item.changeEvent, arguments[0], item)"
            >
              <!-- {{item.label}} -->
            </el-checkbox>
            <!-- 级联选择器 -->
            <el-cascader
              v-if="item.type === 'cascader'"
              :disabled="!item.edit"
              v-model="formData[item.prop]"
              :options="item.options"
              style="width: 100%"
              filterable
              :show-all-levels="item.showAllLevels || false"
            >
            </el-cascader>
            <!-- 日期 -->
            <el-date-picker
              v-if="item.type === 'date'"
              :disabled="!item.edit"
              :editable="false"
              v-model="formData[item.prop]"
              type="date"
              :default-time="item.picker && item.picker.defaultTime"
              value-format="yyyy-MM-dd"
              :format="dateFormat"
              :picker-options="item.picker"
              :placeholder="item.placeholder"
              @change="changeEvent(item.changeEvent, arguments[0], item)"
            >
            </el-date-picker>
            <!-- 日期时间 -->
            <el-date-picker
              v-if="item.type === 'datetime'"
              :disabled="!item.edit"
              :editable="false"
              :default-time="item.picker && item.picker.defaultTime"
              value-format="yyyy-MM-dd HH:mm:ss"
              :format="datetimeFormat"
              v-model="formData[item.prop]"
              type="datetime"
              :picker-options="item.picker"
              :placeholder="item.placeholder"
              @change="changeEvent(item.changeEvent, arguments[0], item)"
            >
            </el-date-picker>
            <!-- 时间 -->
            <el-time-select
              v-if="item.type === 'time'"
              :disabled="!item.edit"
              value-format="HH:mm:ss"
              :format="timeFormat"
              v-model="formData[item.prop]"
              :picker-options="{ start: '00:00', step: '00:15', end: '23:45' }"
              :placeholder="item.placeholder"
              @change="changeEvent(item.changeEvent, arguments[0], item)"
            >
            </el-time-select>
            <!-- 年份 -->
            <el-date-picker
              v-if="item.type === 'dateYear'"
              :disabled="!item.edit"
              :editable="false"
              :default-time="item.picker && item.picker.defaultTime"
              value-format="yyyy"
              v-model="formData[item.prop]"
              type="year"
              :picker-options="item.picker"
              :placeholder="item.placeholder"
              @change="changeEvent(item.changeEvent, arguments[0], item)"
            >
            </el-date-picker>
            <!-- 提醒 -->
            <span class="remind-text" v-if="item.remind">
              {{ item.remind }}
            </span>
          </el-form-item>
        </div>
      </template>
    </el-form>

    <!-- 查找/查找多选 -->
    <el-dialog
      :title="$t('label.searchs')"
      width="60%"
      top="5%"
      :visible.sync="showSearchTable"
      :lock-scroll="true"
      :close-on-click-modal="false"
      :modal="true"
      :before-close="beforeClose"
      append-to-body
    >
      <div
        :style="{
          'min-height': dialogBodyHeight,
          overflow: 'auto',
          padding: '0 20px',
        }"
      >
        <SearchTable
          ref="searchTable"
          :dialogBodyHeight="dialogBodyHeight"
          :fieldId="fieldId"
          :checked="checked"
          :relevant-objid="relevantObjId"
          :relevant-objapi="relevantObjApi"
          :relevant-prefix="relevantPrefix"
          :projectId="projectId"
          :milestone="milestone"
          :taskOrquestion="taskOrquestion"
          @changeSelect="changeSelect"
          @setFieldReltaion="setFieldReltaion"
        />
      </div>
    </el-dialog>
  </div>
</template>

<script type="text/ecmascript-6">
import FormUpload from "@/components/Upload/formUpload";
import { IEVersion } from "@/utils/brower";
import * as Time from "@/utils/time";
import * as CommonObjApi from "./api";
import WangEditor from "@/components/wangEditor/index.vue";
import SearchRemoteTab from "./SearchRemoteTab";
import SearchTable from "./search-table";
import * as projectApi from "./api"; //项目管理系统相关api

export default {
  name: "base-form",
  props: {
    optionList: {
      type: Object,
      default: () => {},
    },
    labelPosition: {
      type: String,
      default: "right",
    },
    width: {
      type: String,
    },
    // 几列布局
    columnNum: {
      type: Number,
      default: 2,
    },
    // 一列占宽百分比
    oneColumnWidth: {
      type: String,
      default: "100%",
    },
    // 两列占宽百分比
    twoColumnWidth: {
      type: String,
      default: "50%",
    },
    statusIcon: {
      // 是否显示验证
      type: Boolean,
      default: true,
    },
    prefix: {
      // 对象前缀
      type: String,
      default: "",
    },
    formAttr: {
      type: Array,
    },
    specialObjectapi: {
      // 用于判断是否为事件类型，动态更改时间
      type: String,
    },
    labelWidth: {
      type: [Number, String],
      default: "264px",
      // default: '150px',
    },
    handleBlur: {
      // 处理移出事件
      type: [Function, undefined],
      default: undefined,
    },
    autoRefresh: {
      // 值发生变化时是否自动刷新
      type: Boolean,
      default: true,
    },
    disabled: {
      // 是否
      type: Boolean,
      default: false,
    },
    detail: {
      // 是否详情页
      type: Boolean,
      default: false,
    },
    id: {
      // 数据id
      type: String,
    },
    objectApi: {
      //对象API
      type: String,
    },
    isClientPoolEditStatus: {
      type: Boolean,
    },
    types: {
      type: String,
    },
    relativeRecordId: {
      // 关联列表记录id
      type: String,
    },
    operationType: {
      // 操作类型
      type: String,
    },
  },
  components: {
    SearchTable,
    FormUpload,
    WangEditor,
    SearchRemoteTab,
  },
  data() {
    return {
      possibilityList: [],
      // 相关项及名称
      defaultPrefix: {},
      eventCheckedObjA: {},
      eventCheckedObjB: {},
      eventIndex: -1,
      eventValueA: "",
      eventValueB: "",
      timeDiff: 0, // 时间差
      dateFormat: Time.dateFormat(this.$cookies.get("countryCode")),
      datetimeFormat: Time.datetimeFormat(this.$cookies.get("countryCode")),
      timeFormat: Time.timeFormat("USA"),
      isEmpty: (rule, value, callback) => {
        if (value && value.toString().trim() === "") {
          // '请输入正确的电子邮箱地址'
          return callback(new Error(this.$i18n.t("label.empty.field")));
        } else {
          callback();
        }
      },
      isEmail: (rule, value, callback) => {
        // eslint-disable-next-line no-useless-escape
        const reg =
          /^(([^<>()\\[\]\\.,;:\s@"]+(\.[^<>()\\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
        if (value && !reg.test(value)) {
          // '请输入正确的电子邮箱地址'
          return callback(
            new Error(this.$i18n.t("label_emailtocloudcc_redmessage1"))
          );
        } else {
          callback();
        }
      },
      isNumber: (rule, value, callback) => {
        if (value !== undefined && isNaN(value)) {
          // 您输入的不是有效数字
          return callback(new Error(this.$i18n.t("label.field.input.number")));
        } else {
          callback();
        }
      },
      //手机号验证规则
      isphone: (rule, value, callback) => {
        // const reg = /^[0-9]*$/;
        // if (!value || !reg.test(value)) {
        //   // 请录入正确的手机格式
        //   return callback(
        //     new Error(this.$i18n.t("front-setting-phone-format"))
        //   );
        // } else {
        //   callback();
        // }
        callback();
      },
      twitter: (rule, value, callback) => {
        if (!value) {
          callback();
        } else {
          if (value.indexOf("twitter.com") === -1) {
            this.isTwitter = false;
            return callback(
              new Error(this.$i18n.t("APPIntegration.Social.twittererror"))
            );
          } else {
            this.isTwitter = true;
            callback();
          }
        }
      },
      linkedin: (rule, value, callback) => {
        if (!value) {
          callback();
        } else {
          if (value.indexOf("linkedin.com") === -1) {
            this.isLinkedin = false;
            return callback(
              new Error(this.$i18n.t("APPIntegration.Social.linkedinerror"))
            );
          } else {
            this.isLinkedin = true;
            callback();
          }
        }
      },
      facebook: (rule, value, callback) => {
        if (!value) {
          callback();
        } else {
          if (value.indexOf("facebook.com") === -1) {
            this.isFacebook = false;
            return callback(
              new Error(this.$i18n.t("APPIntegration.Social.facebookerror"))
            );
          } else {
            this.isFacebook = true;
            callback();
          }
        }
      },
      relevantObjId: "",
      relevantObjApi: "",
      relevantPrefix: "",
      relevantObjectApi: "",
      showSearchTable: false,
      fieldId: "",
      props: {
        checkStrictly: true,
        emitPath: false,
      },
      checked: false,
      formData: {}, // 当前表格数据,
      rules: {}, // 验证规则
      filterable: true, // 是否可检索
      loading: false, // 远程搜索loading
      filterOptions: [], // 远程搜索用于显示的数组
      timer: null, // 当远程搜索频繁改变时用setTimeout减少发送
      fileSize: 1024,
      dialogBodyHeight: `${document.body.offsetHeight - 248}px`,
      stars: null,
      userUrl: "",
      userInfo: {},
      isProjectSystem: false, //是否为项目管理系统
      isTwitter: false,
      isLinkedin: false,
      isFacebook: false,
      taskOrProblemVal: "", //选中的任务/问题的值
      projectId: "",
      milestone: "",
      projectNameFlag: "", //从所属项目名称打开精准搜索
      taskOrquestion: "", //任务、问题id
    };
  },
  created() {
    this.queryLimit();
    this.filterFormData();
    // 如果是edge浏览器 将select可搜索改为false
    if (IEVersion() === "edge") {
      this.filterable = false;
    }
    this.listenStorage();
    this.getUserInfo();
  },
  beforeDestroy() {
    window.removeEventListener("storage", this.listenStorageFn);
  },
  methods: {
    getSymbol() {
      return '"';
    },
    // 处理帮助文本特殊字符回车显示#enter类似问题
    helpText(helpText) {
      if (helpText === undefined || helpText === null) {
        return helpText;
      } else {
        return helpText
          .replace(/#enter/g, "<br />")
          .replace(/#quoteDouble/g, '"')
          .replace(/#quoteSingle/g, "'")
          .replace(/#quote/g, "'");
      }
    },
    // 格式化时间
    formatDateTime(index) {
      var y = new Date().getFullYear();
      var m = new Date().getMonth() + 1;
      m = m < 10 ? "0" + m : m;
      var d = new Date().getDate();
      d = d < 10 ? "0" + d : d;
      let hh =
        (new Date().getHours() < 10
          ? "0" + new Date().getHours()
          : new Date().getHours()) + ":";
      let mm =
        (new Date().getMinutes() < 10
          ? "0" + new Date().getMinutes()
          : new Date().getMinutes()) + ":";
      let ss =
        new Date().getSeconds() < 10
          ? "0" + new Date().getSeconds()
          : new Date().getSeconds();
      if (index == "2") {
        return y + "-" + m + "-" + d + " " + hh + mm + ss;
      }
      return y + "-" + m + "-" + d;
    },
    setStar(nub, item) {
      this.stars = document.querySelectorAll(`.${item.name} span`);

      if (nub === "active") {
        nub = Number(item.value);
      }
      let name = "";
      name = "show2";
      for (var i = 0; i < this.stars.length; i++) {
        // 区分鼠标悬浮和鼠标离开
        this.stars[i].className = i <= nub ? name : "";
      }
    },
    setClick(item, idx) {
      item.value = idx;

      this.formData[item.name] = idx;
    },
    // 事件任务类型相关项选择
    checkItem(index, item) {
      let option = {};
      // this.fieldId = item.fieldId
      option.fieldId = item.fieldId;
      option.fieldType = item.fieldType;
      if (index == 0) {
        this.eventIndex = index;
        if (this.eventValueA) {
          // option.relevantObjId = this.eventValueA
          option.relevantPrefix = this.eventValueA;
          item.optionItem.map((item) => {
            if (item.prefix == this.eventValueA) {
              option.relevantObjId = item.id;
              option.relevantObjApi = item.schemetableName;
            }
          });
          this.eventSearch(option);
        } else {
          this.$message.warning(
            this.$i18n.t("vue_label_commonobjects_detail_selectname")
          );
        }
      } else if (index == 1) {
        this.eventIndex = index;
        if (this.eventValueB) {
          option.relevantPrefix = this.eventValueB;
          item.optionItem.map((item) => {
            if (item.prefix == this.eventValueB) {
              option.relevantObjId = item.id;
              option.relevantObjApi = item.schemetableName;
            }
          });
          this.eventSearch(option);
        } else {
          this.$message.warning(
            this.$i18n.t("vue_label_commonobjects_detail_select_first")
          );
        }
      }
    },
    eventSearch(item) {
      this.fieldId = item.fieldId;
      this.relevantObjId = item.relevantObjId;
      this.relevantObjApi = item.relevantObjApi;
      this.relevantPrefix = item.relevantPrefix;
      this.showSearchTable = true;
      this.checked = item.fieldType === "MR" ? true : false;
      this.$nextTick(() => {
        this.$refs.searchTable.page = 1;
        this.$refs.searchTable.pageObj.dataList = [];
        this.$refs.searchTable.searchConditions = "";
        this.$refs.searchTable.init();
      });
    },
    getDefaultOption() {
      if (
        this.specialObjectapi === "Event" ||
        this.specialObjectapi === "Task"
      ) {
        CommonObjApi.getDialigItem({ type: this.objId }).then((res) => {
          this.defaultPrefix = {
            relate: res.data.relateobjList[0].prefix,
            name: res.data.whoobjInfoList[0].prefix,
          };
          this.formAttr.forEach((item) => {
            if (item.prop === "whoid") {
              if (item.value) {
                this.eventValueA = item.child.whoobj; // 名称
                this.eventCheckedObjA = {
                  id: item.value,
                  name: item.child.whoname,
                };
              } else {
                this.eventValueA = this.defaultPrefix.name; //  名称
              }
            }
            if (item.prop === "relateid") {
              if (item.value) {
                this.eventValueB = item.child.relateobj; // 相关项
                this.eventCheckedObjB = {
                  id: item.value,
                  name: item.child.relatename,
                };
              } else {
                this.eventValueB = this.defaultPrefix.relate; // 相关项
              }
            }
          });
        });
      }
    },
    eventChangeA(e) {
      this.eventValueA = e;
      this.eventCheckedObjA = {};
    },
    eventChangeB(e) {
      this.eventValueB = e;
      this.eventCheckedObjB = {};
    },
    //关联列表点击新建-根据任务 问题 子任务 带值
    getProjectByTask() {
      let params = {
        objectApi: this.$cookies.get("parentObjectApi"),
        recordId: this.relativeRecordId,
      };
      projectApi.getProjectByTask(params).then((res) => {
        // 项目名称代值
        if (res.data && res.data.length > 0) {
          this.$set(this.formData, "project_name", res.data[0].projectid);
          let options = [
            {
              value: res.data[0].projectid,
              label: res.data[0].projectname,
            },
          ];
          this.$parent.$set(this.optionList, "project_name", options);
          // 任务/问题代值
          this.taskOrProblemVal = res.data[0].id;
        }
      });
    },
    // 双向绑定 form name
    filterFormData() {
      this.rules = {};
      this.formData = {};
      this.formAttr &&
        this.formAttr.forEach((v) => {
          // 百分比、数字字段若为空，设置成undefined，不让其显示成最小值
          if (
            (v.fieldType === "P" || v.fieldType === "N") &&
            (v.value === "" || v.value === null)
          ) {
            this.$set(this.formData, v.prop, undefined);
          } else if (v.prop === "recordtype") {
            this.$set(this.formData, v.prop, v.id);
          } else if (v.prop) {
            this.$set(this.formData, v.prop, v.value);
          }
          // 如果有验证规则
          // 字段必填且不只读时加必填规则
          if (v.rules && v.rules.length && v.edit) {
            let name = v.prop;
            v.rules.forEach((val) => {
              if (this.rules[name]) {
                this.rules[name].push(val);
              } else {
                let arr = [];
                arr.push(val);
                this.$set(this.rules, name, arr);
              }
            });

            // 邮件、手机添加校验规则
            if (v.fieldType === "E") {
              this.rules[name].push({ validator: this.isEmail });
            } else if (v.fieldType === "P") {
              this.rules[name].push({ validator: this.isNumber });
            }
            // twitter,linkedin,facebook有验证规则
            if (v.name === "twitter") {
              this.rules[name].push({ validator: this.twitter });
            } else if (v.name === "linkedin") {
              this.rules[name].push({ validator: this.linkedin });
            } else if (v.name === "facebook") {
              this.rules[name].push({ validator: this.facebook });
            }
          } else if (v.fieldType === "E") {
            let arr = [{ validator: this.isEmail }];
            this.$set(this.rules, v.prop, arr);
          } else if (v.fieldType === "P") {
            let arr = [{ validator: this.isNumber }];
            this.$set(this.rules, v.prop, arr);
          } else if (v.name == "mobile") {
            let arr = [{ validator: this.isphone }];
            this.$set(this.rules, v.prop, arr);
          } else if (v.name === "taskOrProblem") {
            // 实际工作清单-任务/问题字段添加必填校验
            let arr = [
              {
                required: true,
                message: this.$i18n.t(
                  "label.projectManagement.please.select.task.or.question"
                ),
                trigger: "blur",
              }, //"请选择任务/问题"
            ];
            this.$set(this.rules, v.prop, arr);
          }

          //必填项不为空字符串校验v.required ,自动编号字段除外
          if (v.required && v.fieldType !== "V" && v.name !== "principal") {
            if (this.rules[v.prop] !== undefined) {
              this.rules[v.prop].push({ validator: this.isEmpty });
            }
          }
        });
      // 获取任务事件下拉框默认选项
      this.formAttr && this.getDefaultOption();

      // 新建时业务机会可能性、预测类别随阶段变化
      if (
        this.operationType === "NEW" &&
        this.prefix === "002" &&
        this.formData["jieduan"] !== undefined
      ) {
        let knx = this.possibilityList.find((item) => {
          return item.val === this.selectVal;
        });
        if (knx !== undefined) {
          // 强制改可能性和预测类别的值 --- 接口处理
          // this.$set(this.formData, 'knx', knx.knx)
          // this.$set(this.formData, 'forecasttype', knx.forecasttype)
          if ("knx" in this.formData) {
            this.formData["knx"] = knx.knx;
          }
          if ("forecasttype" in this.formData) {
            this.formData["forecasttype"] = knx.forecasttype;
          }
        }
      }

      // 详情-关联列表任务、问题、子任务点击实际工作清单新建带值
      if (this.prefix === "p10") {
        this.formAttr && this.getProjectByTask();
      }
      // 事件类型 计算时间差
      if (
        this.specialObjectapi === "Event" &&
        this.formData.endtime &&
        this.formData.begintime
      ) {
        this.timeDiff = this.timeFn(
          this.formData.begintime,
          this.formData.endtime
        );
      }
    },
    resetRules() {
      this.rules = {};
      this.formAttr &&
        this.formAttr.forEach((v) => {
          // 如果有验证规则
          if (v.rules && v.rules.length && v.edit) {
            let name = v.prop;
            v.rules.forEach((val) => {
              if (this.rules[name]) {
                this.rules[name].push(val);
              } else {
                let arr = [];
                arr.push(val);
                this.$set(this.rules, name, arr);
              }
            });

            if (v.fieldType === "E") {
              this.rules[name].push({ validator: this.isEmail });
            } else if (v.fieldType === "P") {
              this.rules[name].push({ validator: this.isNumber });
            }
          } else if (v.fieldType === "E") {
            let arr = [{ validator: this.isEmail }];
            this.$set(this.rules, v.prop, arr);
          } else if (v.fieldType === "P") {
            let arr = [{ validator: this.isNumber }];
            this.$set(this.rules, v.prop, arr);
          } else if (v.name == "mobile") {
            let arr = [{ validator: this.isphone }];
            this.$set(this.rules, v.prop, arr);
          } else if (v.name === "twitter") {
            // twitter,linkedin,facebook无验证规则
            let arr = [{ validator: this.twitter }];
            this.$set(this.rules, v.prop, arr);
          } else if (v.name === "linkedin") {
            let arr = [{ validator: this.linkedin }];
            this.$set(this.rules, v.prop, arr);
          } else if (v.name === "facebook") {
            let arr = [{ validator: this.facebook }];
            this.$set(this.rules, v.prop, arr);
          } else if (v.name === "taskOrProblem") {
            // 实际工作清单-任务/问题字段添加必填校验
            let arr = [
              {
                required: true,
                message: this.$i18n.t(
                  "label.projectManagement.please.select.task.or.question"
                ),
                trigger: "blur",
              }, //"请选择任务/问题"
            ];
            this.$set(this.rules, v.prop, arr);
          }

          //必填项不为空字符串校验v.required,自动编号字段除外
          if (v.required && v.fieldType !== "V" && v.name !== "principal") {
            if (this.rules[v.prop] !== undefined) {
              this.rules[v.prop].push({ validator: this.isEmpty });
            }
          }
        });
    },
    // 获取表单数据
    getData(callback) {
      // 等待修改
      let index = this.filterOptions.findIndex((item) => {
        return this.formData.company === item.value;
      });
      // 改动原因: 需要判断当前选择的公司是否在会员机构之内
      if (index !== -1) {
        this.formData.isCompany = true;
      } else {
        this.formData.isCompany = false;
      }
      this.$refs.elForm.validate((valid) => {
        if (valid) {
          callback(this.formData);
        } else {
          return false;
        }
      });
    },
    // 添加文件
    addFile(item) {
      this.$emit("addFile", item);
    },
    // 文件下载
    downLoadFile(file) {
      let baseURL = this.$baseConfig.baseURL;
      let token = this.$CCDK.CCToken.getToken();
      var link = document.createElement("a");
      link.setAttribute("download", "");
      link.href = `${baseURL}/file/downloadFile?accessToken=${token}&id=${
        file.file_info_id || file.id
      }`;
      link.click();
    },
    // 获取文件大小
    queryLimit() {
      CommonObjApi.queryLimit({ type: "file" }).then((res) => {
        this.fileSize = Number(res.data.slice(0, res.data.length - 2)) * 1024;
      });
    },
    // 文件上传成功
    uploadFileSuccess(prop, fileId, isPng) {
      if (this.formData[prop] === undefined || this.formData[prop] === "") {
        this.$set(this.formData, prop, fileId);
      } else {
        if (isPng) {
          this.$set(this.formData, prop, fileId);
        } else {
          this.$set(this.formData, prop, `${this.formData[prop]}${fileId}`);
        }
      }
    },
    beforeUploadFile() {
      // let formData = new FormData()
      // formData.append('file', file)
      // formData.append('fileName', file.name)
      // formData.append('groupid', '')
      // formData.append('libid', '')
      // formData.append('parentid', '')
      // formData.append('isFromEmail', '')
      // CommonObjApi.upload(formData).then(res => {
      //   this.formData[prop] = res.data.fileinfoid
      // }).catch((err) => {
      //   return false
      // })
    },
    // 清除验证规则
    resetFiled() {
      this.$refs.elForm && this.$refs.elForm.resetFields();
    },
    // 查找/查找多选
    remoteSearch(item) {
      this.taskOrquestion = this.formData.taskOrProblem
        ? this.formData.taskOrProblem
        : "";
      //在项目管理系统下对负责人所属项目的判断
      if (
        item.name == "principal" ||
        item.name == "member" ||
        (window.location.href.substring(window.location.href.length - 3) ==
          "p04" &&
          (item.name == "milestone" || item.name == "affected_milestones"))
      ) {
        if (
          this.formData.project_name == "" ||
          this.formData.their_project == "" ||
          this.formData.their_project_name == ""
        ) {
          this.$message.error(
            this.$i18n.t("label.projectManagement.selectitem")
          ); //请选择项目
          return;
        }
      }
      if (item.prop == "project_name") {
        this.projectNameFlag = "project_name";
      }
      this.milestone = item.name;
      this.projectId =
        this.formData.their_project ||
        this.formData.project_name ||
        this.formData.their_project_name;
      this.eventIndex = -1;
      this.relevantObjApi = "";
      this.fieldId = item.id;
      this.relevantObjId = item.lookupObjid;
      this.relevantPrefix = item.lookupObj;
      this.showSearchTable = true;
      this.checked = item.fieldType === "MR" ? true : false;
      // this.$emit("notZhucongfield");
      this.$nextTick(() => {
        this.$refs.searchTable.page = 1;
        this.$refs.searchTable.pageObj.dataList = [];
        this.$refs.searchTable.searchConditions = "";
        this.$refs.searchTable.init();
      });
    },
    // 改变查找/查找多选的值
    changeSelect(row) {
      if (this.eventIndex == 0) {
        if (row.length === 0) {
          this.eventCheckedObjA = {};
          this.formData.whoobj = "";
        } else {
          this.eventCheckedObjA = row.data;
          this.formData.whoobj = this.eventValueA;
        }
        this.eventIndex = -1;
      } else if (this.eventIndex == 1) {
        if (row.length === 0) {
          this.eventCheckedObjB = {};
          this.formData.relateobj = "";
        } else {
          this.eventCheckedObjB = row.data;
          this.formData.relateobj = this.eventValueB;
        }
        this.eventIndex = -1;
      }
      this.showSearchTable = false;
      // this.$parent.isZhucongfield = false;
      //判断从项目名称打开搜索，且选择不同项目名称进行清空数据
      if (
        this.projectNameFlag == "project_name" &&
        row.data.id != this.formData.project_name
      ) {
        this.porpValueChange();
      }
      this.$emit("changeSelect", row, this.fieldId, this.formData);
      this.projectNameFlag = "";
    },
    // 设置查找筛选条件
    setFieldReltaion(filterFieldIds) {
      let values = [];
      values.push(this.id);
      for (let reltaion in filterFieldIds) {
        let attr = this.formAttr.find((item) => {
          return item.fieldId === filterFieldIds[reltaion].fieldid;
        });
        if (
          attr !== undefined &&
          this.formData[attr.prop] !== null &&
          Array.isArray(this.formData[attr.prop])
        ) {
          values.push(this.formData[attr.prop].join(";"));
        } else if (attr !== undefined && this.formData[attr.prop] !== null) {
          values.push(this.formData[attr.prop]);
        }
      }
      this.$refs.searchTable.filterConditionVals = values;
    },
    beforeClose() {
      this.showSearchTable = false;
    },
    // 远程搜索方法
    remoteMethod(query, item) {
      if (query !== "") {
        setTimeout(() => {
          let params = {
            name: query,
            objId: item.prefix,
            fieldId: item.fieldId,
            lkdp: "",
            page: 1,
            pageSize: 20,
          };
          this.$emit("remoteMethod", params, item, this.formData);
        }, 200);
      }
    },
    // 远程搜索完成选择后清空搜索数组
    clearFilterOptions() {
      this.filterOptions.splice(0);
    },
    // 远程搜索框失去焦点后清空查询列表
    blurEvent() {
      // this.filterOptions = []
    },
    // 选项框发生变化
    selectChange() {
      this.$emit("selectChange", arguments);
    },
    fileChange(params) {
      this.$emit("fileChange", params);
    },
    remove() {
      this.$emit("remove", ...arguments);
    },
    success() {},
    // 内部组件处理鼠标移出事件
    // eslint-disable-next-line no-unused-vars
    handleBlurInChild(item, val, formData, params) {
      // this.handleBlur && this.handleBlur(...arguments)
      // 同一字段变化可能绑定多事件，如查找带值，依赖关系，动态布局规则
      let changeEvent =
        item.changeEvent !== undefined ? item.changeEvent.split(",") : [];
      changeEvent.forEach((eventItem) => {
        if (eventItem === "control") {
          eventItem && this.$emit(eventItem, val, item.fieldId, this.formData);
        } else {
          eventItem && this.$emit(eventItem);
        }
      });
    },
    /**
     * input聚焦事件
     */
    handleFocusEvent(item) {
      // entityFieldName == 'mobile'
      if (item.entityFieldName == "mobile") {
        this.$emit("openBindMobile", item);
      }
    },

    porpValueChange() {
      //清空填写数据
      this.formData.milestone = "";
      this.formData.principal = "";
      this.formData.name = "";
      this.formData.start_date = "";
      this.formData.end_date = "";
      this.formData.milestone = "";
      this.formData.affected_milestones = "";
      this.formData.classification = "";
      this.formData.severity = "";
      this.formData.status = "";
      this.formData.recurrence_frequency = "";
      this.formData.continue_date = "";
      this.formData.billing = "";
      this.formData.problem_description = "";
      this.formData.notice_principal = "";
      this.formData.notification_item = "";
      this.formData.reminder_frequency = "";
    },
    // 值发生改变时候执行的事件
    changeEvent(callback, selectVal, selectItem, fieldType) {
      //项目管理弹窗上添加提示
      if (
        (selectItem.name === "inform_principal" ||
          selectItem.name == "notice_principal") &&
        selectVal == this.$i18n.t("label.tabpage.noz") //"否"
      ) {
        //当您选择否时，将无法通知负责人！
        this.$alert(
          this.$i18n.t("label.projectManagement.the.person.not.be.informed")
        );
      }
      if (
        selectItem.name === "remind_principal" &&
        selectVal == this.$i18n.t("label.tabpage.noz") //"否"
      ) {
        //当您选择“否”时，提醒频率将无法生效
        this.$alert(
          this.$i18n.t("label.projectManagement.alert.frequency.will.not.work")
        );
      }
      if (
        (selectItem.name === "complete_percentage" ||
          selectItem.name === "fulfill_percent") &&
        selectVal == "0%"
      ) {
        this.formData.status = "未开始"; //"未开始"
        this.formData.task_status = "未开始"; //"未开始"
      } else if (
        (selectItem.apiname === "complete_percentage" ||
          selectItem.apiname === "fulfill_percent") &&
        selectVal == "100%" &&
        this.prefix === "p03"
      ) {
        // 项目任务对象特殊处理
        this.formData.status = "已完成"; //已完成
        this.formData.task_status = "已完成"; //已完成
      } else if (
        (selectItem.name === "complete_percentage" ||
          selectItem.name === "fulfill_percent") &&
        selectVal == "100%"
      ) {
        this.formData.status = "完成"; //完成
        this.formData.task_status = "完成"; //完成
      } else if (
        selectItem.name === "complete_percentage" ||
        selectItem.name === "fulfill_percent"
      ) {
        this.formData.status = "进行中"; //"进行中"
        this.formData.task_status = "进行中"; //"进行中"
      }
      // 业务机会对象可能性、预测类别随阶段变化显示对应的值
      if (this.prefix === "002" && selectItem.name === "jieduan") {
        let knx = this.possibilityList.find((item) => {
          return item.val === selectVal;
        });
        if (knx !== undefined) {
          // 强制改可能性和预测类别的值 --- 接口处理
          // this.$set(this.formData, 'knx', knx.knx)
          // this.$set(this.formData, 'forecasttype', knx.forecasttype)
          if ("knx" in this.formData) {
            this.formData["knx"] = knx.knx;
          }
          if ("forecasttype" in this.formData) {
            this.formData["forecasttype"] = knx.forecasttype;
          }
        }
      }

      // 日期时间字段处理
      if (fieldType === "datetime") {
        if (
          this.formData[`${selectItem.prop}Date`] &&
          this.formData[`${selectItem.prop}Time`]
        ) {
          this.$set(
            this.formData,
            selectItem.prop,
            `${this.formData[`${selectItem.prop}Date`]} ${
              this.formData[`${selectItem.prop}Time`]
            }`
          );
        } else {
          this.$set(this.formData, selectItem.prop, "");
        }
      }
      // callback && callback(selectVal, selectItem, this.formData)
      // 同一字段变化可能绑定多事件，如查找带值，依赖关系，动态布局规则
      let changeEvent = callback !== undefined ? callback.split(",") : [];
      if (selectItem.prop !== "recordtype") {
        changeEvent.forEach((eventItem) => {
          if (eventItem === "control") {
            eventItem &&
              this.$emit(
                eventItem,
                selectVal,
                selectItem.fieldId,
                this.formData
              );
          } else {
            eventItem &&
              this.$emit(eventItem, selectVal, selectItem, this.formData);
          }
        });
      }
      // 事件类型自动更改结束时间
      if (
        this.specialObjectapi === "Event" &&
        this.formData.endtime &&
        this.formData.begintime
      ) {
        // 如果更改项为开始时间自动更改结束时间
        if (selectItem.prop === "begintime") {
          this.autoEventDate(this.formData.begintime);
        }
        // 如果更改项为结束时间重新计算时间差
        if (selectItem.prop === "endtime") {
          if (
            this.timeFn(this.formData.begintime, this.formData.endtime) >= 0
          ) {
            this.timeDiff = this.timeFn(
              this.formData.begintime,
              this.formData.endtime
            );
          } else {
            this.formData.endtime = "";
            this.$message({
              showClose: true,
              type: "warning",
              // '结束时间不应早于开始时间'
              message: this.$i18n.t("label.appointment.wizard.notif1"),
            });
          }
        }
      }
    },
    // 计算时间差
    timeFn(d1, d2) {
      //di作为一个变量传进来
      let dateBegin = new Date(d1.replace(/-/g, "/")); //将-转化为/，使用new Date
      let dateEnd = new Date(d2.replace(/-/g, "/")); //将-转化为/，使用new Date
      return dateEnd.getTime() - dateBegin.getTime(); //时间差的毫秒数
    },
    // 满足条件时自动更改结束时间
    autoEventDate(s) {
      let dateBegin = new Date(s.replace(/-/g, "/")); //将-转化为/，使用new Date
      let dateEnd = dateBegin.getTime() + this.timeDiff;
      //时间戳转换方法    d:时间戳数字
      function formatDate(d) {
        let date = new Date(d);
        let YY = date.getFullYear() + "-";
        let MM =
          (date.getMonth() + 1 < 10
            ? "0" + (date.getMonth() + 1)
            : date.getMonth() + 1) + "-";
        let DD = date.getDate() < 10 ? "0" + date.getDate() : date.getDate();
        let hh =
          (date.getHours() < 10 ? "0" + date.getHours() : date.getHours()) +
          ":";
        let mm =
          (date.getMinutes() < 10
            ? "0" + date.getMinutes()
            : date.getMinutes()) + ":";
        let ss =
          date.getSeconds() < 10 ? "0" + date.getSeconds() : date.getSeconds();
        return YY + MM + DD + " " + hh + mm + ss;
      }
      this.formData.endtime = formatDate(dateEnd);
    },
    // 富文本内容变更
    onContentChange(callback, content, selectItem) {
      this.formData[selectItem.prop] = content;
    },
    selecter(cb, data) {
      cb(data);
    },
    // 下拉选项框出现时候触发
    visibleChange(flag) {
      // 如果显示  获取所有下拉选项框 并改变他们的最大宽度
      if (flag) {
        let elSelectDropdown =
          document.getElementsByClassName("el-select-dropdown");
        for (let i = 0; i < elSelectDropdown.length; i++) {
          let el = elSelectDropdown[i];
          el.style.maxWidth = el.style.minWidth;
        }
      }
    },
    // 监听查找字段新增事件
    listenStorage() {
      window.addEventListener("storage", this.listenStorageFn);
    },
    listenStorageFn(event) {
      // 监听addRelevantObjectType变化
      if (event.key === "relevantObjectDataId") {
        let option = {
          value: localStorage.getItem("relevantObjectDataId"),
          label: localStorage.getItem("relevantObjectDataName"),
        };
        this.$emit(
          "addFieldOption",
          localStorage.getItem("relevantObjectApi"),
          option
        );
      }
    },
    // tianyan() {
    //   this.$refs.tian.names(this.formData.name);
    //   this.$refs.tian.handleCloses();
    // },
    //回填客户名
    // setTianyanValue(val) {
    //   let formAttrs = [];
    //   this.formAttr.forEach((item) => {
    //     if (item.name === "name") {
    //       item.value = val;
    //       formAttrs.push(item);
    //     } else {
    //       formAttrs.push(item);
    //     }
    //   });

    //   this.formAttr = formAttrs;
    //   this.filterFormData();
    // },
    // 获取当前用户信息
    async getUserInfo() {
      let res = await CommonObjApi.getUserInfo();
      if (res.result) {
        this.userInfo = res.data;
      }
    },
    //手动输入值
    selectBlur(e, item) {
      this.formData[item.prop] = e.target.value;
    },
  },
  watch: {
    formAttr: {
      handler: function () {
        if (this.autoRefresh) {
          this.filterFormData();
        }
      },

      deep: true,
    },
    optionList() {},
  },
  computed: {
    /**
     * 每个输入框的判断，
     * 使用计算属性，增加公海池的所属公海池，有值就不能编辑的判断
     */
    inputCheckIsDisabled() {
      return (item) => {
        if (item.name === "marketsea") {
          // 如果是公海池对象
          if (item.value && this.isClientPoolEditStatus) {
            // 如果所属公海池对象有值，则禁用，不让其编辑
            return true;
          } else {
            // 否则则让其编辑
            return false;
          }
        } else {
          // 如果不是公海池则返回后台的判断行为
          return !item.edit;
        }
      };
    },
    /**
     * 下拉选项的判断，
     * 增加公海池的所属公海池的下拉选项，去掉【新建】的选项
     */
    // selectCheckIsHiddenNewbulid(item) {
    //   return (item) => {
    //     if (item.objId === "marketsea") {
    //       return false;
    //     } else if (item.objId !== "user") {
    //       return true;
    //     }
    //   };
    // },
  },
};
</script>

<style lang="scss" rel="stylesheet/scss" scoped>
// ::v-deep .el-form {
//   display: flex;
//   align-items: flex-start;
//   flex-wrap: wrap;
// }

.noDataLine {
  height: 62px;
  margin-bottom: 0;
}

// 评分字段显示
.stars {
  list-style: none;
  margin: 0;
  word-break: break-all;
  line-height: 40px;
  color: #ccc;
  // margin-left: 30px;
  float: left;
}

.stars span {
  font-size: 23px;
  margin-left: 4px;
}

.stars span:after {
  content: "☆";
}

.stars .show:after,
.stars .show2:after {
  content: "★";
}

.show2 {
  color: #f7ba2a;
}

// 查找下拉框不显示下拉图标
::v-deep .no-suffix .el-input__suffix {
  right: 25px;
  .el-icon-arrow-up {
    display: none;
  }
}

::v-deep .no-suffix {
  .el-input__inner {
    cursor: text;
  }
}

.remoteBtn {
  position: absolute;
  right: 8px;
  top: 1px;
  color: #c0c4cc;
  z-index: 100;
  cursor: pointer;

  // &:hover{
  //   color:
  // }
}

.helpImg {
  width: 14px;
  margin-left: 5px;
  margin-top: -3px;
}

// facebook、linkedin、twitter图标
.linkedinImg {
  width: 18px;
  height: 18px;
  cursor: pointer;
  margin-top: 10px;
}

.groupTitle {
  // height: 40px;
  line-height: 40px;
  font-size: 16px;
  padding-left: 20px;
  margin-bottom: 1em;
  background: #f0f0f0;
}

.el-date-editor.el-input,
.el-date-editor.el-input__inner {
  width: 100%;
}

.searchTipBox {
  height: 34px;
  line-height: 34px;
  cursor: pointer;
  padding: 0 20px;
  margin-bottom: 0;

  &:hover {
    background: #f5f7fa;
  }
}

.el-input-number {
  width: 100%;

  ::v-deep .el-input__inner {
    text-align: left;
  }
}

::v-deep .el-select__tags {
  margin-top: 5px;
  top: 3%;
  transform: translateY(0%);
}

::v-deep .el-input__inner {
  height: 30px;
}
.event-item {
  display: flex;
  align-items: center;
  .event-item-l {
    width: 200px;
    height: 40px;
  }
  .event-item-r {
    overflow: hidden;
    white-space: nowrap;
    text-overflow: ellipsis;
    background-color: #fff;
    background-image: none;
    border-radius: 4px;
    border: 1px solid #dcdfe6;
    box-sizing: border-box;
    //color: #c3c7cf;
    font-size: inherit;
    height: 30px;
    line-height: 40px;
    outline: 0;
    padding: 0 15px;
    width: 100%;
    display: flex;
    align-items: center;
    font-size: 12px;
  }
}

::v-deep .twitter {
  width: 95% !important;
  margin-right: 10px !important;
}

::v-deep .linkedin {
  width: 95% !important;
  margin-right: 10px !important;
}

::v-deep .facebook {
  width: 95% !important;
  margin-right: 10px !important;
}

::v-deep .dis {
  background-color: #f5f7fa !important;
  border-color: #e4e7ed !important;
}

.el-form-item__label {
  word-break: break-word;
}
</style>
