import axios from '@/config/httpConfig'

/**
 * 请求获取用户信息
 * @param {data} 请求参数 
 * @param {enforce} 是否强制请求最新用户信息 
 * @returns 用户信息
 */
export function getUserInfo(data, enforce = false) {
    if (enforce || localStorage.getItem("userInformation") === null) {
        return axios.get('/user/getUserInfo', data)
    } else {
        return new Promise(function (resolve) {
            resolve(JSON.parse(localStorage.getItem("userInformation"))); // 回调成功返回
        })
    }
}

//关联列表点击新建-根据任务 问题 子任务 带出当前的任务或者问题
export function getProjectByTask(data) {
    return axios.post("/projectWorkList/getProjectByTask", data)
}

//获取项目下的里程碑、任务、子任务
export function getMixedInformation(data) {
    return axios.post("/projectWorkList/getMixedInformation", data)
}

//获取项目下的问题
export function getProblemInformation(data) {
    return axios.post("/problem/getProblemInformation", data)
}

// 获取新建事件相关项
export function getDialigItem(data) {
    return axios.post('activity/getQuickActivityPage', data)
}

// 获取选项列表值
export function getSelectValue(data) {
    return axios.post('/view/getSelectValue', data)
}

//获取文件大小限制
export function queryLimit(data) {
    return axios.post('/file/queryLimit', data)
}

// 获取查找信息
export function getLookupInfo(data) {
    return axios.post('/lookup/getLookupInfo', data)
}

// 获取查找数据
export function getLookupData(data) {
    return axios.post('/lookup/getLookupData', data)
}